import { motion } from "framer-motion";
import React,{ useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import NoDataComp from "../../sharedComponent/NoDataComp";
import { Dropdown } from "react-bootstrap";

import option from "../../assets/images/option-three-dot.svg";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";

const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

function MerchantRemarkCollection({type}){

    const {
        sidebar,
        setSidebar,
        Dark,
        setDark,
        recordsPerPage,
        useDebounce,
        DateFormatConfig,
        setRemarkDetails,
        remarkDetails,
      } = useContext(AppContext);

      const [isLoadin,setIsLoading] = useState(true)


      const [reamrkList,setRemarkList] = useState([])

      const pathname = useLocation().pathname


      const navigate= useNavigate()

      const getRemarkList=()=>{

         simplePostCall(ApiConfig.REMARKS+'remarkList',JSON.stringify({
            page:1,
            page_limit:25,
            type:type
         })).then((res)=>{

            if(res?.result){

                setRemarkList(res?.data)

            }else{

                console.log(res?.message)   

            }

            setIsLoading(false)
            

         }).catch((err)=>{

            console.log(err);

            setIsLoading(false)
            

         })

      }

      useEffect(()=>{
        
        console.log(type);
        

        setIsLoading(true)
        getRemarkList()
      },[pathname])

      const deleteRemark=(id)=>{

        setIsLoading(true)

        simplePostCall(ApiConfig.REMARKS+'delete-remark',JSON.stringify({
            remark_id:id
        })).then((res)=>{

            if(res?.result){

                getRemarkList()

                notifySuccess(res?.message)

            }else{

                notifyError(res?.message)

            setIsLoading(false)

            }

            

        }).catch((err)=>{

            console.log(err);

            setIsLoading(false)
            

        })

      }



    return (
        <>
        
        
        <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper">
<>
                <Link
                  to={type==="delivery"?"/AddRemark/new":"/AddCustomerRemark/new"}
                  className="d-flex justify-content-end mb-2"
                >
                  <button className="cx-btn-3 tbBtn">
                    + Add Remark
                  </button>
                </Link>
              </>
              <div className="main-master-wrapper inner-tabs-section overflow-hidden length-height">
              <div>
                {
                    isLoadin?
                    <Loader/>
                    :reamrkList?.length===0?<>
                    <NoDataComp/>
                    </>:
                    <> 
                    <h5 className="mb-3">Remarks : </h5>            
                    <div className="row">
                    {
                    reamrkList?.map((remark,index)=>{

                        return <div className="col-4 common-vehical-card-inner " key={index}>
                          <div className="d-flex justify-content-between">
                             <div>
                                 <p className="activeColorBrown">{remark?.remark_name}</p>
                             </div>
                             <div className="option customer-option" >
                        <Dropdown>
                            <Dropdown.Toggle>
                              <img src={option} alt="" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item 
                                onClick={()=>{
                                    setRemarkDetails(prev=>({
                                        remark_id:remark?.remark_id,
                                        type:remark?.remark_type,
                                        remark_name:remark?.remark_name
                                      }))

                                      let toPage= type==='delivery'?'/AddRemark/':'/AddCustomerRemark/'

                                      navigate(toPage+remark?.remark_id)
                                }}
                                >
                                    Edit
                                </Dropdown.Item>
                                <Dropdown.Item 
                                onClick={()=>{
                                    deleteRemark(remark?.remark_id)
                                }}
                                >
                                    Delete
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        </div>
                          </div>
                        </div>
                        
                 
            //   return <div className={"common-vehical-card-inner cv-card p-0"} key={index}>
            //         <div className="vehical-card-head">
            //           <div className="heading row">
            //             <div className="col-9">
            //             <h5 >{remark?.remark_name}</h5>

            //             </div>
            //             <div className="option customer-option col-2">
            //             <Dropdown>
            //                 <Dropdown.Toggle>
            //                   <img src={option} alt="" sty/>
            //                 </Dropdown.Toggle>
            //                 <Dropdown.Menu>
            //                     <Dropdown.Item 
            //                     onClick={()=>{
            //                         setRemarkDetails(prev=>({
            //                             remark_id:remark?.remark_id,
            //                             type:remark?.remark_type,
            //                             remark_name:remark?.remark_name
            //                           }))

            //                           navigate('/AddRemark/'+remark?.remark_id)
            //                     }}
            //                     >
            //                         Edit
            //                     </Dropdown.Item>
            //                     <Dropdown.Item 
            //                     onClick={()=>{
            //                         deleteRemark(remark?.remark_id)
            //                     }}
            //                     >
            //                         Delete
            //                     </Dropdown.Item>
            //                 </Dropdown.Menu>
            //             </Dropdown>
            //             </div>
            //           </div>
            //         </div>
                
            //     </div>
                   })
                }
                </div>
                </>
               }
               </div>
               </div> 
        </div>
       
      </motion.div>
      </>

    )

}

export default MerchantRemarkCollection