import React, { useContext, useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from 'react-leaflet';
import { useMemo,useRef } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { getLocationName } from '../api/ApiServices';
import AppState from '../context/AppState';
import NoDataComp from './NoDataComp';
import { AppContext } from '../context/AppContext';

// Fix for missing marker icons in Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});


const AutoCenter=({center})=>{

  const map =useMap()

  useEffect(()=>{

   center && map.setView(center)

  })

  return null

}

const NewMapCommon = (props) => {
  
  const {regionCord} = useContext(AppContext)

  const {
    position,
    handleLocationDragable,
    draggable=true
  } =props

  const [positions, setPosition] = useState([38.7946, 73.8567]); // Initial position
  
  
  const locationDetails=(latlong)=>{

    getLocationName({
      lat:latlong?.lat,
      lng:latlong?.lng
    }).then((res)=>{


      console.log(res);
      

    })

  }

  const DraggableMarker = () => {
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            setPosition(marker.getLatLng())

            const latlong=marker.getLatLng()

            getLocationName({
              lat:latlong?.lat,
              lng:latlong?.lng
            }).then((res)=>{
        
        
              console.log(res);
              
        
            })
            

          }
        },
      }),
      [],
    )

    return (
      <Marker
        position={position}
        draggable={draggable}
        ref={markerRef}
        eventHandlers={eventHandlers}
      />
    );
  };

  return (
    <div className="map-wrapper">
      <div className="leaflet-map">
      <div className="map-main">
            
      <MapContainer center={regionCord} zoom={13} style={{height:'200px',width:'100%',borderRadius:"10px"}}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {
          position&&position[0]&&position[1]&&
          <DraggableMarker />
        }
        <AutoCenter center={position}/>
      </MapContainer>
      
    </div>  
    
      
      
    </div>
    </div>
  );
};

export default NewMapCommon;
