import { Fragment, React, useContext, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import Form from "react-bootstrap/Form";
import XCross from "../../../assets/images/xcross.svg";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import {
  simpleGetCall,
  simplePUTCall,
  simplePostCall,
} from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { useEffect } from "react";
import { notifyError, notifySuccess } from "../../../sharedComponent/notify";
import { SearchFunction } from "../../../sharedComponent/LeafletMap/SearchFunction";
import { useTranslation } from "react-i18next";
import { SearchAddress } from "../../../sharedComponent/LeafletMap/SearchAddress";
import { yearsToDays } from "date-fns";
import { Space, TimePicker } from "antd";
import dayjs from "dayjs";
import MapComponent from "../../../sharedComponent/MapComponent";
import Warehouser_icon from "../../../assets/images/Warehouser_icon.svg";
import NewMapCommon from "../../../sharedComponent/NewMapCommon";
import MobilePhoneInput from "../../../sharedComponent/MobilePhoneInput";

const aninations = {
  initial: { opacity: 0, x: 400 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 100 },
};
const EditCustomerDispatch = () => {
  const { id } = useParams();
  const {
    sidebar,
    setSidebar,
    Dark,
    setDark,
    customerData,
    dispatch_settings_default_unloading_time_duration,
    getTransporatioData,
  } = useContext(AppContext);

  const [validated, setValidated] = useState(false);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState({
    dispatch_customer_address_address: "",
    dispatch_customer_mobile: "",
  });
  const [dispatchDetails, setDispatchDetails] = useState({
    dispatch_customer_name: "",
    dispatch_customer_code: "",
    dispatch_customer_email: "",
    dispatch_customer_mobile: "",
    dispatch_customer_mobile_code: 91,
    dispatch_customer_mobile_without_code: "",
    dispatch_customer_address: [
      {
        dispatch_customer_address_address: "",
        dispatch_customer_address_mobile: "",
        dispatch_customer_address_status: "active",
        dispatch_customer_address_latitude: 0,
        dispatch_customer_address_longitude: 0,
        dispatch_customer_address_country_code: "",
        dispatch_customer_address_unload_duration: dispatch_settings_default_unloading_time_duration,
        dispatch_customer_address_person_name: "",
        dispatch_customer_address_is_default: 0,
        dispatch_customer_created_by: Number(customerData?.id),
        dispatch_customer_address_locality: "",
        isContactNumberChanged: false, // New flag to track manual changes
      },
    ],
  });
  // console.log("dispatchDetails", dispatchDetails);
  // const [customerDetails, setCustomerDetails] = useState({
  //   dispatch_customer_address_address: "",
  //   dispatch_customer_address_mobile: "",
  //   dispatch_customer_address_country_code: "",
  // });
  const [place, setPlace] = useState("");
  const [regionCord, setRegionCord] = useState([]);
  const [draggedName, setDraggedName] = useState("");
  const [data, setData] = useState({
    warehouse: dispatchDetails.dispatch_customer_address,
  });

  // Handler function to update warehouse data from the child component
  // const updateWarehouseData = (updatedData) => {
  //   console.log('Updated Warehouse Data:', updatedData); // Debug log
  //   setData(updatedData);
  //   setDispatchDetails(updatedData.warehouse);
  // };

  useEffect(() => {
    // setDispatchDetails((prevDispatchDetails) => ({
    //   ...prevDispatchDetails,
    //   dispatch_customer_address: [customerDetails],
    // }));

    return () => {
      setDispatchDetails({
        dispatch_customer_name: "",
        dispatch_customer_code: "",
        dispatch_customer_email: "",
        dispatch_customer_mobile: "",
        dispatch_customer_address: [
          {
            dispatch_customer_address_address: "",
            dispatch_customer_address_mobile: "",
            dispatch_customer_address_status: "active",
            dispatch_customer_address_latitude: 0,
            dispatch_customer_address_longitude: 0,
            dispatch_customer_address_country_code: "",
            dispatch_customer_address_unload_duration: "",
            dispatch_customer_address_person_name: "",
            dispatch_customer_address_is_default: 0,
            dispatch_customer_created_by: Number(customerData?.id),
           
            
          },
        ],
      });
    };
  }, []);
  // customerDetails
  const getDispatchDetails = () => {
    setLoading(true);
    simpleGetCall(ApiConfig.GET_VIEW_DISPATCH_DETAILS + id)
      .then((res) => {
        console.log("res,", res);
        if (res.result) {
          setDispatchDetails(res?.data);
          // setCustomerDetails(res?.data?.dispatch_customer_address[0]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (id) getDispatchDetails();
  }, [id]);

  useEffect(() => {
    getTransporatioData();
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (
      !dispatchDetails.dispatch_customer_mobile || customerMobile?.length === 0) {
      setErrMsg({
        ...errMsg,
        dispatch_customer_mobile: "Please Enter Contact Number.",
      });
    }
    if (form.checkValidity() === false || !dispatchDetails.dispatch_customer_mobile || customerMobile?.length === 0 ) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (
        dispatchDetails.dispatch_customer_address[0]
          .dispatch_customer_address_address.length === 0
      ) {
        setErrMsg({
          ...errMsg,
          dispatch_customer_address_address: "Please Enter Address or select",
        });
      } else {
        setLoading(true);
        if (id) {
          // let payLoadBody = JSON.stringify(dispatchDetails);
          let payLoadBody = {
            ...dispatchDetails,
            dispatch_customer_mobile: dispatchDetails?.dispatch_customer_mobile, // Ensure the combined mobile number is included
          };
          simplePUTCall(ApiConfig.UPDATE_DISPATCH_DETAILS, JSON.stringify(payLoadBody))
            .then((res) => {
              if (res?.result === true) {
                // getDispatchDetails();
                notifySuccess(res.message);
                navigate("/DispatchCustomer");
              } else {
                notifyError(res.message);
              }
            })
            .catch((err) => {
              console.log("err", err);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          // let body = JSON.stringify(dispatchDetails);
          let body = {
            ...dispatchDetails,
            dispatch_customer_mobile: dispatchDetails?.dispatch_customer_mobile, // Ensure the combined mobile number is included
          };
          simplePostCall(ApiConfig.UPDATE_DISPATCH_DETAILS, JSON.stringify(body))
            .then((res) => {
              if (res.result) {
                setDispatchDetails(res.data);
                notifySuccess(res.message);
                navigate("/DispatchCustomer");
              } else {
                notifyError(res.message);
              }
            })
            .catch((err) => {
              console.log("err", err);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }
    }
    setValidated(true);
  };

  const handleCustomerNameChange = (e) => {
    const value = e.target.value;
    const valueInput = value.replace(
      /[^A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_{|}~ ]/gi,
      ""
    );

    // If no manual change was made to the Delivery Person Name in the first address, update it
    const updatedAddresses = [...dispatchDetails.dispatch_customer_address];
    if (!updatedAddresses[0].isPersonNameChanged) {
      updatedAddresses[0].dispatch_customer_address_person_name = valueInput;
    }

    setDispatchDetails({
      ...dispatchDetails,
      dispatch_customer_name: valueInput,
      dispatch_customer_address: updatedAddresses,
    });
  };

  const handlePersonNameChange = (e, index) => {
    const updatedAddresses = [...dispatchDetails.dispatch_customer_address];
    updatedAddresses[index] = {
      ...updatedAddresses[index],
      dispatch_customer_address_person_name: e.target.value,
      isPersonNameChanged: true, // Flag to avoid auto-updating when customer name changes
    };
    setDispatchDetails({
      ...dispatchDetails,
      dispatch_customer_address: updatedAddresses,
    });
  };

  // Handle changes in delivery address contact number in Address 1
  const handleContactNumberChange = (e, index) => {
    const value = e.target.value;
    const re = /^[0-9\b]+$/;

    if (value === "" || re.test(value)) {
      const updatedAddresses = [...dispatchDetails.dispatch_customer_address];

      // Update the contact number for the current address
      updatedAddresses[index].dispatch_customer_address_mobile = value;

      // Automatically populate Address 2's contact number if it's not manually changed yet
      if (
        index === 0 &&
        updatedAddresses[1] &&
        !updatedAddresses[1].isContactNumberChanged
      ) {
        updatedAddresses[1].dispatch_customer_address_mobile = value;
      }

      // Mark Address 2 and subsequent addresses as manually changed if they are updated manually
      if (index > 0) {
        updatedAddresses[index].isContactNumberChanged = true;
      }

      setDispatchDetails({
        ...dispatchDetails,
        dispatch_customer_address: updatedAddresses,
      });
    }
  };

  

  // Handle Customer Mobile Changes

 
  
  const handleAddNewAddress = () => {
    setDispatchDetails((prevDetails) => {
      const newAddress = {
        dispatch_customer_address_mobile: prevDetails?.dispatch_customer_address?.[prevDetails.dispatch_customer_address.length - 1]?.dispatch_customer_address_mobile || '',
        isContactNumberChanged: false, // Initially not changed
      };
  
      const updatedAddresses = [...prevDetails.dispatch_customer_address, newAddress];
  
      return {
        ...prevDetails,
        dispatch_customer_address: updatedAddresses,
      };
    });
  
    autoPopulateIfNeeded(dispatchDetails.dispatch_customer_address.length); // Auto-populate the newly added address
  };
  




  const mobileOnChangeHandler = (phone, country) => {
  const countryCode = country?.dialCode;
  const mobileNumber = phone?.replace(country.dialCode, "");
  const combinedContact = `${countryCode} ${mobileNumber}`;

  setDispatchDetails((prevDetails) => {
    const updatedAddresses = [...prevDetails.dispatch_customer_address];

    if (id) {
      return {
        ...prevDetails,
        dispatch_customer_mobile: combinedContact,
      };
    }


    // Update delivery address only if not manually changed
    if (!updatedAddresses[0]?.isContactNumberChanged) {
      updatedAddresses[0].dispatch_customer_address_mobile = combinedContact;
    }
    

    return {
      ...prevDetails,
      dispatch_customer_mobile: combinedContact,
      // dispatch_customer_mobile_code: countryCode,
      dispatch_customer_mobile_without_code: mobileNumber,
      dispatch_customer_address: updatedAddresses,
    };
  }
  
  );



  // setDispatchDetails((prevDetails) => {
  //   // If ID exists, do not update delivery address fields
  //   if (id) {
  //     return {
  //       ...prevDetails,
  //       customer_mobile: combinedContact,
  //     };
  //   }

  //   const updatedAddresses = prevDetails.dispatch_customer_address.map((address) => {
  //     // Auto-update all addresses unless manually modified
  //     if (!address?.isContactNumberChanged) {
  //       return {
  //         ...address,
  //         dispatch_customer_address_mobile: combinedContact,
  //       };
  //     }
  //     return address; // Keep manually modified addresses unchanged
  //   });

  //   return {
  //     ...prevDetails,
  //     customer_mobile: combinedContact,
  //     dispatch_customer_address: updatedAddresses,
  //   };
  // });



};
const handleCustomerMobileChange = (phone, country) => {
  const countryCode = country?.dialCode;
  const mobileNumber = phone?.replace(country.dialCode, "");
  const combinedContact = `${countryCode} ${mobileNumber}`;

  setDispatchDetails((prevDetails) => {
    const updatedAddresses = prevDetails.dispatch_customer_address.map((address, index) => {
      // Auto-update all addresses unless manually modified
      if (!address?.isContactNumberChanged) {
        return {
          ...address,
          dispatch_customer_address_mobile: combinedContact,
        };
      }
      return address; // Keep manually modified addresses unchanged
    });

    return {
      ...prevDetails,
      // customer_mobile: combinedContact,
      dispatch_customer_address_mobile: combinedContact,
      dispatch_customer_address: updatedAddresses,
    };
  });
};

  // Prefill logic for <MobilePhoneInput />
const prefillMobileInput = (combinedMobile, fallbackCode = "91") => {
  const [countryCode, ...mobileParts] = (combinedMobile || "").split(" ");
  const mobileNumber = mobileParts.join(" ");
  return {
    countryCode: countryCode || fallbackCode,
    mobileNumber,
  };
};
  
  const { countryCode, mobileNumber } = prefillMobileInput();
  
    

// Handle Delivery Address Mobile Change



const handleAddressMobileChange = (phone, country, index) => {
  const countryCode = country?.dialCode;
  const mobileNumber = phone?.replace(country.dialCode, "");
  const combinedContact = `${countryCode} ${mobileNumber}`;

  setDispatchDetails((prevDetails) => {
    const updatedAddresses = [...prevDetails.dispatch_customer_address];

    // Update the specific field independently if `id` is true
    updatedAddresses[index] = {
      ...updatedAddresses[index],
      dispatch_customer_address_mobile: combinedContact,
      isContactNumberChanged: true,
    };

    // If no ID exists, propagate changes to subsequent fields
    if (!id) {
      for (let i = index + 1; i < updatedAddresses.length; i++) {
        if (!updatedAddresses[i]?.isContactNumberChanged) {
          updatedAddresses[i] = {
            ...updatedAddresses[i],
            dispatch_customer_address_mobile: combinedContact,
          };
        }
      }
    }

    return {
      ...prevDetails,
      dispatch_customer_address: updatedAddresses,
    };
  });
};






// Prefill values for Customer Mobile
const { countryCode: customerCountryCode, mobileNumber: customerMobile } = prefillMobileInput(dispatchDetails?.dispatch_customer_mobile);

// Prefill values for Address Mobile
const { countryCode: addressCountryCode, mobileNumber: addressMobile } = prefillMobileInput(dispatchDetails.dispatch_customer_address[0]?.dispatch_customer_address_mobile);
const autoPopulateIfNeeded = (index) => {
  setDispatchDetails((prevDetails) => {
    const updatedAddresses = [...prevDetails.dispatch_customer_address];

    if (index > 0 && !updatedAddresses[index]?.isContactNumberChanged) {
      // If not manually changed, auto-populate with the previous index's mobile number
      const prevIndexMobile = updatedAddresses[index - 1]?.dispatch_customer_address_mobile;
      updatedAddresses[index] = {
        ...updatedAddresses[index],
        dispatch_customer_address_mobile: prevIndexMobile,
      };
    }

    return {
      ...prevDetails,
      dispatch_customer_address: updatedAddresses,
    };
  });
};



return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div id="cx-wrapper" className="EditCustomer_Dispatch">
            <div className="main-master-wrapper">
              <div className="Heading">
                <p>
                  {id ? `${t("Edit")}` : `${t("Add")}`} {t("Customer Details")}
                </p>
              </div>
              {/* Inputs form section */}
              <div className="innerInputsGen">
                <div className="row mb-3">
                  <div className="col-md-6  form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t(" Customer Name")}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder={t("Enter Customer Name...")}
                        value={dispatchDetails.dispatch_customer_name}
                        onChange={handleCustomerNameChange}

                        // onChange={(e) => {
                        //   let value = e.target.value;
                        //   let valueInput = value.replace(/[^A-Za-z ]/gi, "");
                        //   setDispatchDetails({
                        //     ...dispatchDetails,
                        //     dispatch_customer_name: valueInput,
                        //   });
                        // }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("Please Enter Customer Name...")}
                      </Form.Control.Feedback>
                    </div>
                  </div>

                  {/* <div className="col-md-6  form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t(" Customer Mobile")}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder={t("Enter Customer Mobile Number...")}
                        value={dispatchDetails?.dispatch_customer_mobile}
                        maxLength="15"
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setDispatchDetails({
                              ...dispatchDetails,
                              dispatch_customer_mobile: e.target.value,
                            });
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("Please Enter Customer Mobile Num...")}
                      </Form.Control.Feedback>
                    </div>
                  </div> */}

                  <div className="col-md-6  form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t(" Customer Mobile")}
                      </Form.Label>
                    
                      {/* <MobilePhoneInput
                      
  commanNumber={mobileNumber} // Prefilled mobile number
  commanContryCode={countryCode} // Prefilled country code
  
  state={dispatchDetails} // Pass the current state
  onChangeHandler={mobileOnChangeHandler} // Handle changes
/> */}

<MobilePhoneInput
  commanNumber={customerMobile}
  commanContryCode={customerCountryCode}
  state={dispatchDetails}
  onChangeHandler={mobileOnChangeHandler}
  // onChangeHandler={(phone, country) => handleCustomerMobileChange(phone, country)}
/>


                      {errMsg?.dispatch_customer_mobile?.length > 0 && (
                        <span className="error-class-t">
                          {errMsg?.dispatch_customer_mobile}
                        </span>
                      )}
                      <Form.Control.Feedback type="invalid">
                        {t("Please Enter Your Contact Number")}
                      </Form.Control.Feedback>
                    </div>
                  </div>

                  <div className="col-md-6  form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {" "}
                        {t(" Customer Email")} (optional)
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Enter Customer Email...")}
                        value={dispatchDetails.dispatch_customer_email}
                        onChange={(e) =>
                          setDispatchDetails({
                            ...dispatchDetails,
                            dispatch_customer_email: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Add new details sec */}
              <div className="AddNewForm">
                {dispatchDetails?.dispatch_customer_address &&
                  dispatchDetails?.dispatch_customer_address?.map(
                    (address, index) => {
                     {/* // Use the previous address's mobile number for the current one, if applicable
      const mobileNumber = index > 0 
        ? dispatchDetails?.dispatch_customer_address[index - 1]?.dispatch_customer_address_mobile 
        : address?.dispatch_customer_address_mobile;

      const { countryCode, mobileNumber: prefilledMobile } = prefillMobileInput(mobileNumber); */}
        // Get the mobile number from the first address for all subsequent addresses
        {/* const mobileNumber = dispatchDetails?.dispatch_customer_address[0]?.dispatch_customer_address_mobile || address?.dispatch_customer_address_mobile;
      
      const { countryCode, mobileNumber: prefilledMobile } = prefillMobileInput(mobileNumber); */}


                      return (
                        <Fragment key={"addresss" + index}>
                          <div className="innerWrapper">
                            <div className="FormHeading">
                              <p>
                                {t("Address") + " " + (index + 1)}
                                {/* -{index + 1} */}
                              </p>
                            </div>
                            <div className="innerImg">
                              {index > 0 && (
                                <img
                                  src={XCross}
                                  alt=""
                                  onClick={() => {
                                    if (
                                      dispatchDetails.dispatch_customer_address
                                        .length > 1
                                    )
                                      setDispatchDetails({
                                        ...dispatchDetails,
                                        dispatch_customer_address: dispatchDetails.dispatch_customer_address.filter(
                                          (_, filterIndex) =>
                                            filterIndex != index
                                        ),
                                      });
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6  form_input_main mb-3">
                              <div className="innerSelectBox weekCounter">
                                <Form.Label className="common-labels">
                                  {t("Delivery Person Name")} (optional)
                                </Form.Label>
                                <Form.Control
                                  // required
                                  type="text"
                                  // maxLength="6"
                                  name="Speed_limit"
                                  value={
                                    dispatchDetails.dispatch_customer_address[
                                      index
                                    ].dispatch_customer_address_person_name
                                  }
                                  // onChange={(e) => {

                                  //   const updatedAddresses = [
                                  //     ...dispatchDetails.dispatch_customer_address,
                                  //   ];
                                  //   updatedAddresses[index] = {
                                  //     ...updatedAddresses[index],
                                  //     dispatch_customer_address_person_name:
                                  //       e.target.value,
                                  //   };
                                  //   setDispatchDetails({
                                  //     ...dispatchDetails,
                                  //     dispatch_customer_address:
                                  //       updatedAddresses,
                                  //   });
                                  // }}
                                  onChange={(e) =>
                                    handlePersonNameChange(e, index)
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {t("Please Enter Load Time...")}
                                </Form.Control.Feedback>
                              </div>
                            </div>
                            <div className="col-md-6  form_input_main mb-3">
                              <div className="innerSelectBox weekCounter">
                                <Form.Label className="common-labels">
                                  {t("Delivery Address Country Code")}{" "}
                                  (optional)
                                </Form.Label>
                                <Form.Control
                                  //required
                                  type="text"
                                  maxLength="3"
                                  name="Speed_limit"
                                  value={
                                    dispatchDetails.dispatch_customer_address[
                                      index
                                    ].dispatch_customer_address_country_code
                                  }
                                  onChange={(e) => {
                                    const updatedAddresses = [
                                      ...dispatchDetails.dispatch_customer_address,
                                    ];
                                    updatedAddresses[index] = {
                                      ...updatedAddresses[index],
                                      dispatch_customer_address_country_code:
                                        e.target.value,
                                    };
                                    setDispatchDetails({
                                      ...dispatchDetails,
                                      dispatch_customer_address: updatedAddresses,
                                    });
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {t("Please Enter Address Country Code...")}
                                </Form.Control.Feedback>
                              </div>
                            </div>
                            <div className="col-md-6  form_input_main mb-3">
                              <div className="innerSelectBox weekCounter">
                                <Form.Label className="common-labels">
                                  {t("Delivery Address Contact Number")}{" "}
                                  (optional)
                                </Form.Label>
                                


                <MobilePhoneInput
              commanNumber={address.dispatch_customer_address_mobile}
              commanContryCode={address.dispatch_customer_address_country_code}
              state={address}
              onChangeHandler={(phone, country) => handleAddressMobileChange(phone, country, index)}
            />
                                <Form.Control.Feedback type="invalid">
                                  {t(
                                    "Please Enter Delivery Address Contact Number..."
                                  )}
                                </Form.Control.Feedback>
                              </div>
                            </div>
                            <div className="col-md-6  form_input_main mb-3">
                              <div className="innerSelectBox weekCounter">
                                <Form.Label className="common-labels">
                                  {t("Average Unloading Duration (Min) ")}{" "}
                                  (optional)
                                </Form.Label>
                                <Form.Control
                                  //required
                                  type="number"
                                  maxLength="6"
                                  name="Speed_limit"
                                  value={
                                    dispatchDetails.dispatch_customer_address[
                                      index
                                    ].dispatch_customer_address_unload_duration
                                  }
                                  onChange={(e) => {
                                    const re = /^[0-9\b]+$/;
                                    if (
                                      e.target.value === "" ||
                                      re.test(e.target.value)
                                    ) {
                                      const updatedAddresses = [
                                        ...dispatchDetails.dispatch_customer_address,
                                      ];
                                      updatedAddresses[index] = {
                                        ...updatedAddresses[index],
                                        dispatch_customer_address_unload_duration:
                                          e.target.value,
                                      };
                                      setDispatchDetails({
                                        ...dispatchDetails,
                                        dispatch_customer_address: updatedAddresses,
                                      });
                                    }
                                  }}
                                />

                                <Form.Control.Feedback type="invalid">
                                  {t("Please Enter Load Time...")}
                                </Form.Control.Feedback>
                              </div>
                            </div>

                            <div className="col-md-6 mb-3">
                              <div className="innerSelectBox weekCounter">
                                <Form.Label className="common-labels">
                                  {t("Delivery Address")}
                                </Form.Label>
                                <SearchAddress
                                  displayValue={
                                    address?.dispatch_customer_address_address
                                  }
                                  required={true}
                                  state={
                                    dispatchDetails?.dispatch_customer_address
                                  }
                                  setState={setDispatchDetails}
                                  index={index}
                                  keyProperty={"dispatch_customer_address"}
                                  addressKey={
                                    "dispatch_customer_address_address"
                                  }
                                  latKey={"dispatch_customer_address_latitude"}
                                  lngKey={"dispatch_customer_address_longitude"}
                                  countyCode={
                                    "dispatch_customer_address_country_code"
                                  }
                                />
                                {errMsg.dispatch_customer_address_address
                                  .length > 0 && (
                                  <span className="text-danger">
                                    {errMsg.dispatch_customer_address_address}
                                  </span>
                                )}

                                <Form.Control.Feedback type="invalid">
                                  {t("Please Enter Delivery Address...")}
                                </Form.Control.Feedback>
                              </div>
                            </div>

                            <div className="col-md-6  form_input_main">
                              <div className="innerSelectBox weekCounter">
                                <Form.Label className="common-labels">
                                  {t(" Locality")} (optional)
                                </Form.Label>
                                <Form.Control
                                  // required
                                  type="text"
                                  placeholder={t("Enter Locality..")}
                                  value={
                                    address?.dispatch_customer_address_locality
                                  }
                                  onChange={(e) => {
                                    const updatedAddresses = [
                                      ...dispatchDetails.dispatch_customer_address,
                                    ];
                                    updatedAddresses[index] = {
                                      ...updatedAddresses[index],
                                      dispatch_customer_address_locality:
                                        e.target.value,
                                    };
                                    setDispatchDetails({
                                      ...dispatchDetails,
                                      dispatch_customer_address: updatedAddresses,
                                    });
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {t("Please Enter Locality...")}
                                </Form.Control.Feedback>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className=" addCustomerDispatch m-0 p-0">
                                <MapComponent
                                  componentId={"EditCustomerDispatch"}
                                  data={dispatchDetails}
                                  dragable={true}
                                  currentIndex={index}
                                  latlong={{
                                    lat:
                                      address.dispatch_customer_address_latitude,
                                    long:
                                      address.dispatch_customer_address_longitude,
                                  }}
                                  locationName={
                                    address?.dispatch_customer_address_address
                                  }
                                  handleLocation={(loc, latLng) => {
                                    setDispatchDetails((prevDetails) => {
                                      const updatedWarehouses = [
                                        ...prevDetails.dispatch_customer_address,
                                      ];

                                      updatedWarehouses[index] = {
                                        ...updatedWarehouses[index],
                                        dispatch_customer_address_address:
                                          loc.display_name,
                                        dispatch_customer_address_latitude:
                                          latLng.lat,
                                        dispatch_customer_address_longitude:
                                          latLng.lng,
                                      };

                                      return {
                                        ...prevDetails,
                                        dispatch_customer_address: updatedWarehouses,
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      );
                    }
                  )}
              </div>
              <button
                className="AddNewBtn"
                type="button"
                onClick={handleAddNewAddress}
               
              >
                + {t("Add New")}
              </button>
              <div className="d-flex justify-content-end align-items-center belowBtns btn-wrapper">
                <button
                  className="cx-btn-1"
                  onClick={() => {
                    navigate("/DispatchCustomer");
                  }}
                >
                  {t("Cancel")}
                </button>

                <button className="cx-btn-2">
                  {id ? `${t("Update")}` : `${t("Submit")}`}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </motion.div>
    </>
  );
};

export default EditCustomerDispatch;
