export const ITEMS = [
  {
    first: "1",
    second: "Kolhapur",
    third: "Kolhapur",
    fourth: "Yes",
    fifth: "Kolhapur City",
    sixth: "1",
  },
  {
    first: "2",
    second: "Pune",
    third: "Pune",
    fourth: "Yes",
    fifth: "Pune City",
    sixth: "3",
  },
  {
    first: "3",
    second: "Aurangabad",
    third: "Aurangabad",
    fourth: "Yes",
    fifth: "Aurangabad City",
    sixth: "2",
  },
];
