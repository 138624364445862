// Usama 09-02-2023
import React, { useContext, useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Carousel, Col, Dropdown, Modal, OverlayTrigger, Tooltip, Tab, Tabs } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import Export from "../../assets/images/ic-Export.svg";
import Import from "../../assets/images/ic-Import.svg";
import { motion } from "framer-motion";
import Nav from "react-bootstrap/Nav";
import Cat_ye_car from "../../assets/images/Catagiry_yellow_car.svg";
import { Link, redirect, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PostCallWithErrorResponse, simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import Pagenation from "../../sharedComponent/Pagenation";
import NoDataComp from "../../sharedComponent/NoDataComp";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";


const OfferVehicleMarketPlace = () => {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);

  const [searchKey, setSearchKey] = useState('');
  const [vehicleCategory, setvehicleCategory] = useState('');
  const [vehicleCapacity, setVehicleCapacity] = useState('');
  const [vehicleTransportationType, setVehicleTransportationType] = useState('');

  const [vehicleOfferList, setVehicleOfferList] = useState({
    list: [],
    next_page: false,
    total: 0
  });
  const [vehicleAssignedList, setVehicleAssignedList] = useState({
    list: [],
    next_page: false,
    total: 0
  });
  const [VehicleAvailableList, setVehicleAvailableList] = useState({
    list: [],
    next_page: false,
    total: 0
  });
  const [vehicleBlockList, setVehicleBlockList] = useState({
    list: [],
    next_page: false,
    total: 0
  });

  useEffect(() => {
    
  }, [vehicleBlockList])
  
  const [vehicleAllList, setVehicleAllList] = useState({
    list: [],
    next_page: false,
    total: 0
  });


  const [vehicleListType, setVehicleListType] = useState(1);
  const [selectedObj, setSelectedObj] = useState([]);
  const [last_page, setlast_page] = useState(false);
  const [page, setPage] = useState(1);
  const [bottom, setBottom] = useState("");
  const [checkedItems, setCheckedItems] = useState({});

  // Function to handle checkbox toggle
  const handleCheckboxChange = (index, data) => {
    // setSelectedObj([...selectedObj, data]);
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [index]: !prevCheckedItems[index],
    }));
    if (selectedObj.includes(data)) {
      setSelectedObj((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item !== data)
      );
    } else {
      // If it's not checked, add it to the array
      setSelectedObj((prevSelectedItems) => [...prevSelectedItems, data]);
    }
  };

  const handleClose = () => setShow(false);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  let api_key = localStorage?.getItem("api_key") || "";
  // let api_key = '2ed20897c7e2db4144b10b200cb9a326';
  let user_customer_id = Number(localStorage?.getItem("customer_id")) || "";
  let user_id = localStorage?.getItem("id") || "";


  const getAllVehicleList = (api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory, vehicleTransportationType) => {
    setLoader(true);
    let payload = {
      customer_id: user_customer_id,
      api_key: api_key,
      transporter_id: 6576,
      search_key: searchKey,
      limit: 100,
      page: page,
      vehicle_capacity: vehicleCapacity,
      vehicle_category: vehicleCategory,
      vehicle_transportation_type: vehicleTransportationType
    };

    simplePostCall(ApiConfig.MARKET_PLACE_ALL_VEHICLE_LIST, JSON.stringify(payload))
      .then((res) => {
        setLoader(true);
        if (res.result) {
          setVehicleAllList(prev => {
            return (
              {
                ...prev,
                list: [...prev.list, ...res.list],
                next_page: res.next_page,
                total: res.total
              }
            )
          })
          setLoader(false);
        }
        setLoader(false);
      })
      .catch((error) => {
        console.error('Error fetching vehicle list:', error);
        setLoader(false);
      });
  };

  const getOfferedVehicleList = (api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory, vehicleTransportationType) => {

    setLoader(true);
    let payload = {
      customer_id: user_customer_id,
      api_key: api_key,
      transporter_id: 6576,
      search_key: searchKey,
      limit: 100,
      page: page,
      vehicle_capacity: vehicleCapacity,
      vehicle_category: vehicleCategory,
      vehicle_transportation_type: vehicleTransportationType
    };

    simplePostCall(ApiConfig.MARKET_PLACE_VEHICLE_OFFERED_LIST, JSON.stringify(payload))
      .then((res) => {
        if (res.result) {
          // setVehicleOfferList(res);
          setVehicleOfferList(prev => {
            return (
              {
                ...prev,
                list: [...prev.list, ...res.list],
                next_page: res.next_page,
                total: res.total
              }
            )
          })
          setLoader(false);
        }
        setLoader(false);
      }).catch((error) => {
        console.log(error)
      })
  };

  const getAssignedVehicleList = (api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory, vehicleTransportationType) => {
    setLoader(true);
    let payload = {
      customer_id: user_customer_id,
      api_key: api_key,
      transporter_id: 6576,
      search_key: searchKey,
      limit: 100,
      page: page,
      vehicle_capacity: vehicleCapacity,
      vehicle_category: vehicleCategory,
      vehicle_transportation_type: vehicleTransportationType
    };

    simplePostCall(ApiConfig.MARKET_PLACE_VEHICLE_ASSIGN_LIST, JSON.stringify(payload))
      .then((res) => {
        if (res.result) {
          // setVehicleAssignedList(res)
          setVehicleAssignedList(prev => {
            return (
              {
                ...prev,
                list: [...prev.list, ...res.list],
                next_page: res.next_page,
                total: res.total
              }
            )
          })

          console.log("Assigned Vehicle ", vehicleAssignedList)
          setLoader(false);
        }
        setLoader(false);
      }).catch((error) => {
        console.log(error)
      })
  };

  const getAvailableVehicleList = (api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory, vehicleTransportationType) => {
    setLoader(true);
    let payload = {
      customer_id: user_customer_id,
      api_key: api_key,
      transporter_id: 6576,
      search_key: searchKey,
      limit: 100,
      page: page,
      vehicle_capacity: vehicleCapacity,
      vehicle_category: vehicleCategory,
      vehicle_transportation_type: vehicleTransportationType
    };

    simplePostCall(ApiConfig.MARKET_PLACE_AVAILABLE_LIST, JSON.stringify(payload))
      .then((res) => {
        if (res.result) {
          // setVehicleAvailableList(res)
          setVehicleAvailableList(prev => {
            return (
              {
                ...prev,
                list: [...prev.list, ...res.list],
                next_page: res.next_page,
                total: res.total
              }
            )
          })
          setLoader(false);
        }
        setLoader(false);
      }).catch((error) => {
        console.log(error)
      })
  };

  const getBlockVehicleList = (api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory, vehicleTransportationType) => {
    setLoader(true);
    let payload = {
      customer_id: user_customer_id,
      api_key: api_key,
      transporter_id: 6576,
      search_key: searchKey,
      limit: 100,
      page: page,
      vehicle_capacity: vehicleCapacity,
      vehicle_category: vehicleCategory,
      vehicle_transportation_type: vehicleTransportationType
    };

    simplePostCall(ApiConfig.MARKET_PLACE_BLOCK_LIST, JSON.stringify(payload))
      .then((res) => {
        if (res.result) {
          // setVehicleBlockList(res)
          setVehicleBlockList(prev => {
            return (
              {
                ...prev,
                list: [...prev.list, ...res.list],
                next_page: res.next_page,
                total: res.total
              }
            )
          })
          setLoader(false);
        }
        setLoader(false);
      }).catch((error) => {
        console.log(error)
      })
  };
  const getAssignedVehicleListFreshStart = (api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory, vehicleTransportationType) => {
    setLoader(true);
    let payload = {
      customer_id: user_customer_id,
      api_key: api_key,
      transporter_id: 6576,
      search_key: searchKey,
      limit: 100,
      page: page,
      vehicle_capacity: vehicleCapacity,
      vehicle_category: vehicleCategory,
      vehicle_transportation_type: vehicleTransportationType
    };

    simplePostCall(ApiConfig.MARKET_PLACE_VEHICLE_ASSIGN_LIST, JSON.stringify(payload))
      .then((res) => {
        if (res.result) {
          // setVehicleAssignedList(res)
          setVehicleAssignedList(prev => {
            return (
              {
                ...prev,
                list: [ ...res.list],
                next_page: res.next_page,
                total: res.total
              }
            )
          })

          console.log("Assigned Vehicle ", vehicleAssignedList)
          setLoader(false);
        }
        setLoader(false);
      }).catch((error) => {
        console.log(error)
      })
  };
  const enableOfferVehicle = (api_key, user_customer_id, Vehicle_id) => {
    setVehicleCapacity("")
    setvehicleCategory("")
    setSearchKey("")
    let payload = {
      customer_id: user_customer_id,
      api_key: api_key,
      vehicle_id: Vehicle_id,
      transporter_id: 6576,
    };

    simplePostCall(ApiConfig.MARKET_PLACE_ENABLE_VEHICLE_OFFER, JSON.stringify(payload))
      .then((res) => {
        if (res.result) {
          setVehicleAllList(prev => ({ ...prev, list: [], next_page: 0 }));
          getOfferedVehicleList(api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory)
          getAvailableVehicleList(api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory);
          getAllVehicleList(api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory)

        }

      }).catch((error) => {

        console.log(error)
      })
  }

  const disableOfferVehicle = (api_key, user_customer_id, Vehicle_id) => {
    setVehicleCapacity("")
    setvehicleCategory("")
    setSearchKey("")

    let payload = {
      customer_id: user_customer_id,
      api_key: api_key,
      vehicle_id: Vehicle_id,
      transporter_id: 6576,
    };

    simplePostCall(ApiConfig.MARKET_PLACE_DISABLE_VEHICLE_OFFER, JSON.stringify(payload))
      .then((res) => {
        if (res.result) {
          setVehicleAllList(prev => ({ ...prev, list: [], next_page: 0 }));
          getAllVehicleList(api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory)
          getOfferedVehicleList(api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory)
          getAvailableVehicleList(api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory);
        }

      }).catch((error) => {

        console.log(error)
      })
  }

  const blockVehicle = (vehicle_id) => {

    let payload = {
      customer_id: user_customer_id,
      vehicle_id: vehicle_id
    }
    simplePostCall(ApiConfig.MARKET_PLACE_BLOCK_VEHICLE, JSON.stringify(payload))
      .then((res) => {
        if (res.result) {
          setLoader(true);
          getAssignedVehicleListFreshStart(api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory, vehicleTransportationType);
          getBlockVehicleList(api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory);
          notifySuccess(res.message)
        }else{
          notifyError(res.message)
        }

      }).catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    AOS.init({ duration: 2000 });

    // planning to fetch all list then show it 
    getOfferedVehicleList(api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory);
    getAssignedVehicleList(api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory);
    getAvailableVehicleList(api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory);
    getBlockVehicleList(api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory);
  }, []);

  // fetch the vihecleList according to the header
  useEffect(() => {
    switch (vehicleListType) {
      case 1:
        // fetch all vehicle
        setVehicleAllList(prev => ({ ...prev, list: [], next_page: 0 }));
        getAllVehicleList(api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory, vehicleTransportationType);
        break;
      case 2:
        // fetch offered vehicle
        setVehicleOfferList(prev => ({ ...prev, list: [], next_page: 0 }));
        getOfferedVehicleList(api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory, vehicleTransportationType);
        break;
      case 3:
        // fetch assigned vehicle
        setVehicleAssignedList(prev => ({ ...prev, list: [], next_page: 0 }));
        getAssignedVehicleList(api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory, vehicleTransportationType);
        break;
      case 4:
        // fetch available vehicle
        setVehicleAvailableList(prev => ({ ...prev, list: [], next_page: 0 }));
        getAvailableVehicleList(api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory, vehicleTransportationType);
        break;
      case 5:
        // fetch block vehicle
        setVehicleBlockList(prev => ({ ...prev, list: [], next_page: 0 }));
        getBlockVehicleList(api_key, user_customer_id, page, searchKey, vehicleCapacity, vehicleCategory, vehicleTransportationType);
        break;

      default:
        break;
    }
  }, [vehicleListType, searchKey, vehicleCapacity, vehicleCategory, vehicleTransportationType])


  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  useEffect(() => {

  }, [searchKey])

  const renderTooltipForBlock = props => (
    <Tooltip id='button-tooltip' {...props}>
      {t('Block')}
    </Tooltip>
  )

  return (
    <motion.div
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
    >
      <div id="cx-wrapper" className="Vehicle_Main">
        <div
          className="Vehcle-main-tabs cx-marketPlace-main"
          id="cx-marketPlace"
        >
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="pills" id="newTabMai" className="tob_nav_pills">
              <Nav.Item>
                <Nav.Link
                  eventKey="first"
                  onClick={() => {
                    setVehicleListType(1)
                    setPage(1)
                    setVehicleCapacity("")
                    setvehicleCategory("")
                    setSearchKey("")
                  }}
                >
                  {t(`All Vehicles (${vehicleAllList.total ? vehicleAllList.total : 0})`)}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="second"
                  onClick={() => {
                    setVehicleListType(2)
                    setPage(1)
                    setVehicleCapacity("")
                    setvehicleCategory("")
                    setSearchKey("")
                  }}
                >
                  {t(`Offered (${vehicleOfferList.total ? vehicleOfferList.total : 0})`)}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => {
                    setVehicleListType(3)
                    setPage(1)
                    setVehicleCapacity("")
                    setvehicleCategory("")
                    setSearchKey("")
                  }}
                  eventKey="three"
                >
                  {t(`Assigned Vehicles (${vehicleAssignedList.total ? vehicleAssignedList.total : 0})`)}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="four"
                  onClick={() => {
                    setVehicleListType(4)
                    setPage(1)
                    setVehicleCapacity("")
                    setvehicleCategory("")
                    setSearchKey("")
                  }}
                >
                  {t(`Available (${VehicleAvailableList?.total ? VehicleAvailableList?.total : 0})`)}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="Five"
                  onClick={() => {
                    setVehicleListType(5)
                    setPage(1)
                    setVehicleCapacity("")
                    setvehicleCategory("")
                    setSearchKey("")
                  }}
                >
                  {t(`Blocked (${vehicleBlockList?.total ? vehicleBlockList?.total : 0})`)}
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="main-master-wrapper mb-0 inner-tabs-section overflow-hidden">
                  <div id="scroll_insideThe_Padding">
                    <div className="all-vehicle-main">
                      <div className="all-vehical-head row vehicle-top-inputs">
                        <div className="input-section-wrapper">
                          <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                value={searchKey}
                                onChange={(e) => {
                                  setSearchKey(e.target.value)
                                }}
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Category"
                                value={vehicleCategory}
                                onChange={(e) => {
                                  setvehicleCategory(e.target.value)
                                }}
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Capacity"
                                value={vehicleCapacity}
                                onChange={(e) => {
                                  setVehicleCapacity(e.target.value)
                                }}
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                              <select
                                required
                                className="form-select"
                                aria-label="Default select example"
                                placeholder="Transportation Type"
                                onChange={(e) => {
                                  setVehicleTransportationType(e.target.value)
                                }}
                              >
                                <option selected value="">Transportation Type</option>
                                <option value="Passenger">Passenger</option>
                                <option value="Goods">Goods</option>
                                <option value="Frozen Goods">Frozen Goods</option>
                                <option value="Medicine">Medicine</option>
                                <option value="Food Items">Food Items</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="right-export-btn-section-wrapper">
                          <div className="c-pointer me-2">
                            <img src={Export} alt="" />
                          </div>
                          <div className="c-pointer">
                            <img src={Import} alt="" />
                          </div>
                        </div>
                      </div>
                      {
                        loader ? <Loader /> :
                          vehicleAllList && vehicleAllList.list?.length > 0 ?
                            <div className="yauto" id="arrange-paading"
                              onScroll={(e) => {
                                setBottom(e ? true : false)
                                const bottom =
                                  e.target.scrollHeight - e.target.scrollTop ===
                                  e.target.clientHeight

                                if (vehicleAllList.next_page != false) {
                                  if (bottom && !last_page) {
                                    setPage(vehicleAllList.next_page);
                                    getAllVehicleList(api_key, user_customer_id, vehicleAllList.next_page)
                                  }
                                }
                              }}>
                              <div className="row main-cards-wrapper gx-3">
                                {
                                  vehicleAllList?.list?.map((itemvehicle, index) => {
                                    console.log(" items vehicle ", itemvehicle)
                                    return (
                                      <div
                                        className={
                                          sidebar
                                            ? "col-lg-6 col-md-6"
                                            : "col-lg-4 col-md-6"
                                        }
                                        key={index}
                                      >
                                        <div className={"common-cat-vehical-card-inner"}>
                                          <div className="cat-body w-100">
                                            <div
                                              className="head-with-img"
                                              id="head-with-img-switch"
                                            >
                                              <div id="head-with-img">
                                                <div className="left img">
                                                  <img src={Cat_ye_car} alt="" />
                                                </div>
                                                <div className="right v-name cat-body-discription">
                                                  <label htmlFor="">
                                                    {t("Vehicle Number")}
                                                  </label>
                                                  <p>{itemvehicle.plate_number ? itemvehicle.plate_number : "No Data"}</p>
                                                </div>
                                              </div>

                                              <div
                                                className="form-check form-switch"
                                                id="custom_switch"
                                              >
                                                {/* <label htmlFor={`checkbox-${index}`}>Hello</label> */}
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id={`checkbox-${index}`}
                                                  checked={itemvehicle.offer_status === 1} // Simplified boolean expression
                                                  onChange={(e) => {
                                                    const isChecked = e.target.checked; // Check if the checkbox is checked or unchecked

                                                    if (isChecked) {
                                                      setVehicleCapacity("")
                                                      setvehicleCategory("")
                                                      setSearchKey("")
                                                      enableOfferVehicle(api_key, user_customer_id, itemvehicle.vehicle_id);
                                                    } else {
                                                      setVehicleCapacity("")
                                                      setvehicleCategory("")
                                                      setSearchKey("")
                                                      disableOfferVehicle(api_key, user_customer_id, itemvehicle.vehicle_id);
                                                    }

                                                    handleCheckboxChange(index, itemvehicle);
                                                  }}
                                                />

                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-lg-6 cat-body-discription mt-2">
                                                <label htmlFor="">
                                                  {t("Vehicle Number")}
                                                </label>
                                                <p>{itemvehicle.plate_number ? itemvehicle.plate_number : "No Data"}</p>
                                              </div>
                                              <div className="col-lg-6 cat-body-discription mt-2">
                                                <label htmlFor="">{t("Driver Name")}</label>
                                                <p>{itemvehicle?.user_name ? itemvehicle?.user_name : ''}</p>
                                              </div>
                                              <div className="col-lg-6 cat-body-discription mt-2">
                                                <label htmlFor="">{t("IMEI No.")}</label>
                                                <p>{itemvehicle?.vehicle_imei ? itemvehicle?.vehicle_imei : "No Data"}</p>
                                              </div>
                                              <div className="col-lg-6 cat-body-discription mt-2">
                                                <label htmlFor="">
                                                  {t("Vehicle Type")}
                                                </label>
                                                <p>{itemvehicle.vehicle_type ? itemvehicle.vehicle_type : "No Data"}</p>
                                              </div>
                                              <div className="col-lg-6 cat-body-discription mt-2">
                                                <label htmlFor="">
                                                  {t("Vehicle Capacity")}
                                                </label>
                                                <p>{itemvehicle.capacity ? itemvehicle.capacity : "No Data"}</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })
                                }

                              </div>
                            </div>
                            : <div colSpan={20} className='text-center'><NoDataComp /></div>
                      }
                    </div>
                  </div>
                  {/* <p className="reg-color mt-3"> */}
                  <Pagenation length={vehicleAllList?.list?.length} total={vehicleAllList?.total} />
                  {/* </p> */}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div className="main-master-wrapper mb-0 inner-tabs-section overflow-hidden">
                  <div id="scroll_insideThe_Padding">
                    <div className="all-vehicle-main">
                      <div className="all-vehical-head row vehicle-top-inputs">
                        <div className="input-section-wrapper">
                          <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                onChange={(e) => {
                                  setSearchKey(e.target.value)
                                }}
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Category"
                                onChange={(e) => {
                                  setvehicleCategory(e.target.value)
                                }}
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Capacity"
                                onChange={(e) => {
                                  setVehicleCapacity(e.target.value)
                                }}
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                              <select
                                required
                                className="form-select"
                                aria-label="Default select example"
                                placeholder="Transportation Type"
                                onChange={(e) => {
                                  setVehicleTransportationType(e.target.value)
                                }}
                              >
                                <option selected value="">Transportation Type</option>
                                <option value="Passenger">Passenger</option>
                                <option value="Goods">Goods</option>
                                <option value="Frozen Goods">Frozen Goods</option>
                                <option value="Medicine">Medicine</option>
                                <option value="Food Items">Food Items</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="right-export-btn-section-wrapper">
                          <div className="c-pointer me-2">
                            <img src={Export} alt="" />
                          </div>
                          <div className="c-pointer">
                            <img src={Import} alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="yauto" id="arrange-paading"
                        onScroll={(e) => {
                          setBottom(e ? true : false)
                          const bottom =
                            e.target.scrollHeight - e.target.scrollTop ===
                            e.target.clientHeight

                          if (vehicleOfferList?.next_page) {
                            if (bottom && !last_page) {
                              setPage(page + 1);
                              getOfferedVehicleList(api_key, user_customer_id, vehicleOfferList.next_page, searchKey, vehicleCapacity, vehicleCategory);
                            }
                          }

                        }}>
                        {
                          loader ? <Loader /> :

                            vehicleOfferList && vehicleOfferList.list?.length > 0 ?
                              <>
                                <div className="row main-cards-wrapper gx-3">
                                  {
                                    vehicleOfferList?.list.map((data, index) => {
                                      return (
                                        <div
                                          className={
                                            sidebar
                                              ? "col-lg-6 col-md-6"
                                              : "col-lg-4 col-md-6"
                                          }
                                        >
                                          <div className={"common-cat-vehical-card-inner"}>
                                            <div className="cat-body w-100">
                                              <div className="head-with-img">
                                                <div className="left img">
                                                  <img src={Cat_ye_car} alt="" />
                                                </div>
                                                <div className="right v-name cat-body-discription">
                                                  <label htmlFor="">
                                                    {t("Vehicle Number")}
                                                  </label>
                                                  <p>{data.plate_number == null || undefined ? "No Data" : data.plate_number}</p>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-lg-6 cat-body-discription mt-2">
                                                  <label htmlFor="">
                                                    {t("Vehicle Number")}
                                                  </label>
                                                  <p>{data.plate_number == null || undefined ? "No Data" : data.plate_number}</p>
                                                </div>
                                                {/* <div className="col-lg-6 cat-body-discription mt-2">
                                                <label htmlFor="">{t("Driver Name")}</label>
                                                <p>Mr. John Doe</p>
                                              </div> */}
                                                <div className="col-lg-6 cat-body-discription mt-2">
                                                  <label htmlFor="">{t("IMEI No.")}</label>
                                                  <p>{data.vehicle_imei == null || undefined ? "No Data" : data.vehicle_imei}</p>
                                                </div>
                                                <div className="col-lg-6 cat-body-discription mt-2">
                                                  <label htmlFor="">
                                                    {t("Vehicle Type")}
                                                  </label>
                                                  <p>{data.vehicle_type == null || undefined ? "No Data" : data.vehicle_type}</p>
                                                </div>
                                                <div className="col-lg-6 cat-body-discription mt-2">
                                                  <label htmlFor="">
                                                    {t("Vehicle Capacity")}
                                                  </label>
                                                  <p>{data.capacity == null || undefined ? "No Data" : data.capacity}</p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </>
                              : <div colSpan={20} className='text-center'><NoDataComp /></div>
                        }
                      </div>
                    </div>
                  </div>
                  {
                    vehicleOfferList && vehicleOfferList.list?.length > 0 ?
                      <Pagenation length={vehicleOfferList.list.length} total={vehicleOfferList.total} /> :
                      null
                  }
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="three">
                <div className="main-master-wrapper mb-0 inner-tabs-section overflow-hidden">
                  <div id="scroll_insideThe_Padding">
                    <div className="all-vehicle-main">
                      <div className="all-vehical-head row vehicle-top-inputs">
                        <div className="input-section-wrapper">
                          <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                onChange={(e) => {
                                  setSearchKey(e.target.value)
                                }}
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Category"
                                onChange={(e) => {
                                  setvehicleCategory(e.target.value)
                                }}
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Capacity"
                                onChange={(e) => {
                                  setVehicleCapacity(e.target.value)
                                }}
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                              <select
                                required
                                className="form-select"
                                aria-label="Default select example"
                                placeholder="Transportation Type"
                                onChange={(e) => {
                                  setVehicleTransportationType(e.target.value)
                                }}
                              >
                                <option selected value="">Transportation Type</option>
                                <option value="Passenger">Passenger</option>
                                <option value="Goods">Goods</option>
                                <option value="Frozen Goods">Frozen Goods</option>
                                <option value="Medicine">Medicine</option>
                                <option value="Food Items">Food Items</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="right-export-btn-section-wrapper">
                          <div className="c-pointer me-2">
                            <img src={Export} alt="" />
                          </div>
                          <div className="c-pointer">
                            <img src={Import} alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="yauto" id="arrange-paading"
                        onScroll={(e) => {
                          setBottom(e ? true : false)
                          const bottom =
                            e.target.scrollHeight - e.target.scrollTop ===
                            e.target.clientHeight


                          if (vehicleAssignedList?.next_page) {
                            if (bottom && !last_page) {
                              setPage(page + 1);
                              getOfferedVehicleList(api_key, user_customer_id, vehicleAssignedList.next_page, searchKey, vehicleCapacity, vehicleCategory);
                            }
                          }

                        }}>
                        {
                          loader ? <Loader /> :

                            (vehicleAssignedList && vehicleAssignedList.list.length > 0) ?
                              <>
                                <div className="row main-cards-wrapper gx-3">
                                  {
                                    vehicleAssignedList.list.map((data, index) => {
                                      return (
                                        <div
                                          className={
                                            sidebar
                                              ? "col-lg-6 col-md-6"
                                              : "col-lg-4 col-md-6"
                                          }
                                        >
                                          <div className={"common-cat-vehical-card-inner"}>
                                            <div className="cat-body w-100">
                                              <div className="head-with-img">
                                                <div className="left img">
                                                  <img src={Cat_ye_car} alt="" />
                                                </div>
                                                <div className="right v-name cat-body-discription">
                                                  <label htmlFor="">
                                                    {t("Vehicle Number")}
                                                  </label>
                                                  <p>{data.plate_number == null || undefined ? "No Data" : data.plate_number}</p>
                                                </div>


                                                <div className="" style={{ maxWidth: "fit-content", marginLeft: "auto", color: "#8f4300", cursor: "pointer" }} onClick={()=>{
                                                  blockVehicle(data.vehicle_id)
                                                }}>
                                                  <i class="pi pi-ban" style={{ fontSize: "2rem" }}></i>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-lg-6 cat-body-discription mt-2">
                                                  <label htmlFor="">
                                                    {t("Vehicle Number")}
                                                  </label>
                                                  <p>{data.plate_number == null || undefined ? "No Data" : data.plate_number}</p>
                                                </div>
                                                {/* <div className="col-lg-6 cat-body-discription mt-2">
                                                <label htmlFor="">{t("Driver Name")}</label>
                                                <p>Mr. John Doe</p>
                                              </div> */}
                                                <div className="col-lg-6 cat-body-discription mt-2">
                                                  <label htmlFor="">{t("IMEI No.")}</label>
                                                  <p>{data.vehicle_imei == null || undefined ? "No Data" : data.vehicle_imei}</p>
                                                </div>
                                                <div className="col-lg-6 cat-body-discription mt-2">
                                                  <label htmlFor="">
                                                    {t("Vehicle Type")}
                                                  </label>
                                                  <p>{data.vehicle_type == null || undefined ? "No Data" : data.vehicle_type}</p>
                                                </div>
                                                <div className="col-lg-6 cat-body-discription mt-2">
                                                  <label htmlFor="">
                                                    {t("Vehicle Capacity")}
                                                  </label>
                                                  <p>{data.capacity == null || undefined ? "No Data" : data.capacity}</p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </>
                              : <div colSpan={20} className='text-center'><NoDataComp /></div>
                        }
                      </div>
                    </div>
                  </div>
                  {
                    vehicleAssignedList && vehicleAssignedList.list?.length > 0 ?
                      <Pagenation length={vehicleAssignedList.list?.length} total={vehicleAssignedList.total} /> :
                      null
                  }
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="four">
                <div className="main-master-wrapper mb-0 inner-tabs-section overflow-hidden">
                  <div id="scroll_insideThe_Padding">
                    <div className="all-vehicle-main">
                      <div className="all-vehical-head row vehicle-top-inputs">
                        <div className="input-section-wrapper">
                          <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                onChange={(e) => {
                                  setSearchKey(e.target.value)
                                }}
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Category"
                                onChange={(e) => {
                                  setvehicleCategory(e.target.value)
                                }}
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Capacity"
                                onChange={(e) => {
                                  setVehicleCapacity(e.target.value)
                                }}
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                              <select
                                required
                                className="form-select"
                                aria-label="Default select example"
                                placeholder="Transportation Type"
                                onChange={(e) => {
                                  setVehicleTransportationType(e.target.value)
                                }}
                              >
                                <option selected value="">Transportation Type</option>
                                <option value="Passenger">Passenger</option>
                                <option value="Goods">Goods</option>
                                <option value="Frozen Goods">Frozen Goods</option>
                                <option value="Medicine">Medicine</option>
                                <option value="Food Items">Food Items</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="right-export-btn-section-wrapper">
                          <div className="c-pointer me-2">
                            <img src={Export} alt="" />
                          </div>
                          <div className="c-pointer">
                            <img src={Import} alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="yauto" id="arrange-paading"
                        onScroll={(e) => {
                          setBottom(e ? true : false)
                          const bottom =
                            e.target.scrollHeight - e.target.scrollTop ===
                            e.target.clientHeight

                          if (vehicleAssignedList?.next_page) {
                            if (bottom && !last_page) {
                              setPage(page + 1);
                              getAvailableVehicleList(api_key, user_customer_id, vehicleAssignedList?.next_page, searchKey, vehicleCapacity, vehicleCategory)
                            }
                          }

                        }}>
                        {
                          loader ? <Loader /> :

                            VehicleAvailableList && VehicleAvailableList?.list?.length > 0 ?
                              <>
                                <div className="row main-cards-wrapper gx-3">
                                  {
                                    VehicleAvailableList?.list?.map((data, index) => {
                                      return (
                                        <div
                                          className={
                                            sidebar
                                              ? "col-lg-6 col-md-6"
                                              : "col-lg-4 col-md-6"
                                          }
                                        >
                                          <div className={"common-cat-vehical-card-inner"}>
                                            <div className="cat-body w-100">
                                              <div className="head-with-img">
                                                <div className="left img">
                                                  <img src={Cat_ye_car} alt="" />
                                                </div>
                                                <div className="right v-name cat-body-discription">
                                                  <label htmlFor="">
                                                    {t("Vehicle Number")}
                                                  </label>
                                                  <p>{data.plate_number == null || undefined ? "No Data" : data.plate_number}</p>
                                                </div>

                                                <div className="" style={{ maxWidth: "fit-content", marginLeft: "auto", color: "#8f4300", cursor: "pointer" }}>
                                                  <OverlayTrigger
                                                    placement='bottom'
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipForBlock}
                                                  >
                                                    <i class="pi pi-ban" style={{ fontSize: "2rem" }}></i>
                                                  </OverlayTrigger>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-lg-6 cat-body-discription mt-2">
                                                  <label htmlFor="">
                                                    {t("Vehicle Number")}
                                                  </label>
                                                  <p>{data.plate_number == null || undefined ? "No Data" : data.plate_number}</p>
                                                </div>
                                                {/* <div className="col-lg-6 cat-body-discription mt-2">
                                                <label htmlFor="">{t("Driver Name")}</label>
                                                <p>Mr. John Doe</p>
                                              </div> */}
                                                <div className="col-lg-6 cat-body-discription mt-2">
                                                  <label htmlFor="">{t("IMEI No.")}</label>
                                                  <p>{data.vehicle_imei == null || undefined ? "No Data" : data.vehicle_imei}</p>
                                                </div>
                                                <div className="col-lg-6 cat-body-discription mt-2">
                                                  <label htmlFor="">
                                                    {t("Vehicle Type")}
                                                  </label>
                                                  <p>{data.vehicle_type == null || undefined ? "No Data" : data.vehicle_type}</p>
                                                </div>
                                                <div className="col-lg-6 cat-body-discription mt-2">
                                                  <label htmlFor="">
                                                    {t("Vehicle Capacity")}
                                                  </label>
                                                  <p>{data.capacity == null || undefined ? "No Data" : data.capacity}</p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </>
                              : <div colSpan={20} className='text-center'><NoDataComp /></div>
                        }
                      </div>
                    </div>
                  </div>
                  {
                    VehicleAvailableList && VehicleAvailableList.list?.length > 0 ?
                      <Pagenation length={VehicleAvailableList.list.length} total={VehicleAvailableList.total} /> :
                      null
                  }
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Five">
                <div className="main-master-wrapper mb-0 inner-tabs-section overflow-hidden">
                  <div id="scroll_insideThe_Padding">
                    <div className="all-vehicle-main">
                      <div className="all-vehical-head row vehicle-top-inputs">
                        <div className="input-section-wrapper">
                          <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Name,No., Reg. No, IMEI..."
                                onChange={(e) => {
                                  setSearchKey(e.target.value)
                                }}
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Category"
                                onChange={(e) => {
                                  setvehicleCategory(e.target.value)
                                }}
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Capacity"
                                onChange={(e) => {
                                  setVehicleCapacity(e.target.value)
                                }}
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                              <select
                                required
                                className="form-select"
                                aria-label="Default select example"
                                placeholder="Transportation Type"
                                onChange={(e) => {
                                  setVehicleTransportationType(e.target.value)
                                }}
                              >
                                <option selected value="">Transportation Type</option>
                                <option value="Passenger">Passenger</option>
                                <option value="Goods">Goods</option>
                                <option value="Frozen Goods">Frozen Goods</option>
                                <option value="Medicine">Medicine</option>
                                <option value="Food Items">Food Items</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="right-export-btn-section-wrapper">
                          <div className="c-pointer me-2">
                            <img src={Export} alt="" />
                          </div>
                          <div className="c-pointer">
                            <img src={Import} alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="yauto" id="arrange-paading"
                        onScroll={(e) => {
                          setBottom(e ? true : false)
                          const bottom =
                            e.target.scrollHeight - e.target.scrollTop ===
                            e.target.clientHeight


                          if (vehicleAssignedList?.next_page) {
                            if (bottom && !last_page) {
                              setPage(page + 1);
                              getBlockVehicleList(api_key, user_customer_id, vehicleAssignedList?.next_page, searchKey, vehicleCapacity, vehicleCategory)
                            }
                          }

                        }}>
                        {
                          loader ? <Loader /> :

                            (vehicleBlockList.list && vehicleBlockList.list.length > 0) ?
                              <>
                                <div className="row main-cards-wrapper gx-3">
                                  {
                                    vehicleBlockList.list.map((data, index) => {
                                      return (
                                        <div
                                          className={
                                            sidebar
                                              ? "col-lg-6 col-md-6"
                                              : "col-lg-4 col-md-6"
                                          }
                                        >
                                          <div className={"common-cat-vehical-card-inner"}>
                                            <div className="cat-body w-100">
                                              <div className="head-with-img">
                                                <div className="left img">
                                                  <img src={Cat_ye_car} alt="" />
                                                </div>
                                                <div className="right v-name cat-body-discription">
                                                  <label htmlFor="">
                                                    {t("Vehicle Number")}
                                                  </label>
                                                  <p>{data.plate_number == null || undefined ? "No Data" : data.plate_number}</p>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-lg-6 cat-body-discription mt-2">
                                                  <label htmlFor="">
                                                    {t("Vehicle Number")}
                                                  </label>
                                                  <p>{data.plate_number == null || undefined ? "No Data" : data.plate_number}</p>
                                                </div>
                                                {/* <div className="col-lg-6 cat-body-discription mt-2">
                                                <label htmlFor="">{t("Driver Name")}</label>
                                                <p>Mr. John Doe</p>
                                              </div> */}
                                                <div className="col-lg-6 cat-body-discription mt-2">
                                                  <label htmlFor="">{t("IMEI No.")}</label>
                                                  <p>{data.vehicle_imei == null || undefined ? "No Data" : data.vehicle_imei}</p>
                                                </div>
                                                <div className="col-lg-6 cat-body-discription mt-2">
                                                  <label htmlFor="">
                                                    {t("Vehicle Type")}
                                                  </label>
                                                  <p>{data.vehicle_type == null || undefined ? "No Data" : data.vehicle_type}</p>
                                                </div>
                                                <div className="col-lg-6 cat-body-discription mt-2">
                                                  <label htmlFor="">
                                                    {t("Vehicle Capacity")}
                                                  </label>
                                                  <p>{data.capacity == null || undefined ? "No Data" : data.capacity}</p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </>
                              : <div colSpan={20} className='text-center'><NoDataComp /></div>
                        }
                      </div>
                    </div>
                  </div>
                  {
                    VehicleAvailableList && VehicleAvailableList.length > 0 ?
                      <Pagenation length={VehicleAvailableList.length} total={VehicleAvailableList.total} /> :
                      null
                  }
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="common-model"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Delete Vehicle")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {t("Are you sure you want to delete this vehicle")} ?
            </Modal.Body>
            <Modal.Footer className="pop-up-modal-footer btn-wrapper">
              <button className="cx-btn-1" onClick={handleClose}>
                {t("Close")}
              </button>
              <button className="cx-btn-2" onClick={handleClose}>
                {t("Yes")}
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </motion.div>
  );
};

export default OfferVehicleMarketPlace;
