import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../context/AppContext";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cat_ye_car from "../../../assets/images/Catagiry_yellow_car.svg";
import { simplePostCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import Loader from "../../../sharedComponent/Loader";
import NoDataComp from "../../../sharedComponent/NoDataComp";

const DirectOrderConfirmation = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const param = useParams();
  const vehicleReuestId = param.id;
  const [loader, setLoader] = useState(false);

  const [orderDetails, setOrderDetails] = useState(null)
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  useEffect(() => {
    AOS.init({ duration: 2000 });
    getDirectConfirmOrders();
  }, []);




  const getDirectConfirmOrders = () => {

    const payload = {
      "customer_id": 1000,
      "api_key": localStorage.getItem("api_key"),
      "vehicle_request_id": Number(vehicleReuestId)
    }
    setLoader(true);
    simplePostCall(ApiConfig.CONFIRM_ORDER_LIST, JSON.stringify(payload))
      .then(res => {
        if (res.success) {
          setOrderDetails(res?.data);
        }
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        setLoader(false);
      })
  }
  return (
    <motion.div
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
    >

      {
        loader ? <Loader /> : <>

          <div id="cx-wrapper" className="order_details">
            <div className="row">
              <div className="col-md-12">
                <div className="detailsCard">
                  {!orderDetails ? <NoDataComp></NoDataComp> : <>
                    <div className="headingBox">
                      <h1>Order Details</h1>
                    </div>
                    <div className="contentBox">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="flexCOntent">
                            <label htmlFor="" className="key">
                              {t("Pickup Location")}
                            </label>
                            <label htmlFor="" className="value">
                              {orderDetails?.vehicle_request_pickup_location ? orderDetails?.vehicle_request_pickup_location : "No Data"}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="flexCOntent">
                            <label htmlFor="" className="key">
                              {t("Drop Location")}
                            </label>
                            <label htmlFor="" className="value">
                              {orderDetails?.vehicle_request_pickup_point ? orderDetails?.vehicle_request_drop_location : "No Data"}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="flexCOntent">
                            <label htmlFor="" className="key">
                              {t("Pickup Date")}
                            </label>
                            <label htmlFor="" className="value">
                              {orderDetails?.vehicle_request_pickup_date ? orderDetails.vehicle_request_pickup_date : "No Data"}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="flexCOntent">
                            <label htmlFor="" className="key">
                              {t("Pickup Time")}
                            </label>
                            <label htmlFor="" className="value">
                              {orderDetails?.vehicle_request_pickup_time ? orderDetails?.vehicle_request_pickup_time : "No Data"}
                            </label>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="flexCOntent">
                            <label htmlFor="" className="key">
                              {t("Drop Date")}
                            </label>
                            <label htmlFor="" className="value">
                              {orderDetails?.vehicle_request_drop_date ? orderDetails?.vehicle_request_drop_date : "No Data"}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="flexCOntent">
                            <label htmlFor="" className="key">
                              {t("Drop Time")}
                            </label>
                            <label htmlFor="" className="value">
                              {orderDetails?.vehicle_request_drop_time ? orderDetails?.vehicle_request_drop_time : "No Data"}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="flexCOntent">
                            <label htmlFor="" className="key">
                              {t("Number of Vehicles")}
                            </label>
                            <label htmlFor="" className="value">
                              {orderDetails?.vehicle_request_number_of_vehicles ? orderDetails?.vehicle_request_number_of_vehicles : "No Data"}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="flexCOntent">
                            <label htmlFor="" className="key">
                              {t("Name")}
                            </label>
                            <label htmlFor="" className="value">
                              {orderDetails?.vehicle_request_customer_name ? orderDetails?.vehicle_request_customer_name : "No Data"}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="flexCOntent">
                            <label htmlFor="" className="key">
                              {t("Contact No ")}
                            </label>
                            <label htmlFor="" className="value">
                              {orderDetails?.vehicle_request_customer_number ? orderDetails?.vehicle_request_customer_number : "No Data"}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="flexCOntent">
                            <label htmlFor="" className="key">
                              {t("Email")}
                            </label>
                            <label htmlFor="" className="value">
                              {orderDetails?.vehicle_request_customer_email ? orderDetails?.vehicle_request_customer_email : "No Data"}
                            </label>
                          </div>
                        </div>

                        {/* <div className="col-md-3">
                    <div className="flexCOntent">
                      <label htmlFor="" className="key">
                        {t("Driver Name")}
                      </label>
                      <label htmlFor="" className="value">
                        LD-1234567891-1141
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="flexCOntent">
                      <label htmlFor="" className="key">
                        {t("Driver Contact No.")}
                      </label>
                      <label htmlFor="" className="value">
                        LD-1234567891-1141
                      </label>
                    </div>
                  </div> */}
                      </div>
                    </div>
                  </>


                  }
                </div>
              </div>
            </div>
          </div>
          {
            orderDetails &&

            <div id="cx-wrapper" className="order_details mt-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="detailsCard">
                    <div className="headingBox">
                      <h1>Vehicles</h1>
                    </div>
                    {/* <div className="contentBox"> */}
                    <div className="row  main-cards-wrapper gx-3">

                      {
                        orderDetails?.vehicles?.length > 0 ? orderDetails.vehicles.map((item, index) => (
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div
                                  className="head-with-img"
                                  id="head-with-img-switch"
                                >
                                  <div id="head-with-img "
                                    style={{ height: "50px", display: "flex", gap: 20 }}
                                  >
                                    <div className="h-100">
                                      <img src={Cat_ye_car} className="h-100" alt="" />
                                    </div>
                                    <div className="right v-name cat-body-discription">
                                      <label htmlFor="">
                                        {t("Vehicle Number")}
                                      </label>
                                      <p>{item?.plate_number}</p>
                                    </div>

                                    {/* <div
                                className="form-check form-switch self-end h-100 ml-auto"
                                id="custom_switch"
                              >
                               <label htmlFor={`checkbox-${index}`}>Hello</label> 
                              <input
                                className="form-check-input "
                                // style={{ height: "25px", width: "50px !important" }}
                                type="checkbox"
                              // id={`checkbox-${index}`}
                              // checked={itemvehicle.offer_status === 1} // Simplified boolean expression
                              // onChange={(e) => {
                              //   const isChecked = e.target.checked; // Check if the checkbox is checked or unchecked

                              //   if (isChecked) {
                              //     setVehicleCapacity("")
                              //     setvehicleCategory("")
                              //     setSearchKey("")
                              //     enableOfferVehicle(api_key, user_customer_id, itemvehicle.vehicle_id);
                              //   } else {
                              //     setVehicleCapacity("")
                              //     setvehicleCategory("")
                              //     setSearchKey("")
                              //     disableOfferVehicle(api_key, user_customer_id, itemvehicle.vehicle_id);
                              //   }

                              //   handleCheckboxChange(index, itemvehicle);
                              // }}
                              />
                            </div> */}
                                  </div>

                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>{item?.plate_number}</p>
                                  </div>
                                  {/* <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>{'driver name'}</p>
                                  </div> */}
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>{item?.vehicle_imei}</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>{item?.vehicle_type}</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>{item?.capacity}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                          : <>
                            <NoDataComp />
                          </>
                      }


                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          }


        </>
      }
    </motion.div >
  );
};

export default DirectOrderConfirmation;
