// Usama 09-02-2023
import React, { useContext, useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Carousel, Col, Dropdown, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { AppContext } from "../../../context/AppContext";
import "@splidejs/react-splide/css";
import ic_check_accept from "../../../assets/images/ic_check_accept.svg";
import Export from "../../../assets/images/ic-Export.svg";
import Import from "../../../assets/images/ic-Import.svg";
import ic_reject_cross from "../../../assets/images/ic_reject_cross.svg";
import { motion } from "framer-motion";
import Delete from "../../../assets/images/delete.svg";
import View from "../../../assets/images/Group.svg";
import Nav from "react-bootstrap/Nav";
import ic_r_arrow from "../../../assets/images/ic_r_arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import Pagenation from "../../../sharedComponent/Pagenation";
import { useTranslation } from "react-i18next";
import { simplePostCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import Loader from "../../../sharedComponent/Loader";
import CommonDatePicker from "../../../sharedComponent/CommonDatePicker";
import { ConfigProvider, Space, Table, TimePicker } from "antd";
import Select from "react-select";
import NoDataComp from "../../../sharedComponent/NoDataComp";
import { tr } from "date-fns/locale";
import { toast } from "react-toastify";
import { notifyError, notifySuccess } from "../../../sharedComponent/notify";
import _ from 'lodash'
import moment from "moment";
import { color } from "highcharts";
import { render } from "@testing-library/react";
import AntTablewithFilterandSort from "../../../sharedComponent/AntTablewithFilterandSort";

const DirectOrderMarketPlace = () => {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false)
  const [show2, setShow2] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const [bottom, setBottom] = useState("");
  const [page, setPage] = useState(1);

  const [vehicleListType, setVehicleListType] = useState(1)
  const [selectedVehicle, setSelectedVehicle] = useState();

  const [myOrderList, setMyOrderList]= useState({
    data: [],
    next_page: false,
    total: 0
  })

  const [loadList, setLoadList] = useState({
    data: [],
    next_page: false,
    total: 0
  })
  const [confirmList, setConfirmList] = useState({
    data: [],
    next_page: false,
    total: 0
  })
  const [vehicleOfferList, setVehicleOfferList] = useState({
    data: [],
    next_page: false,
    total: 0
  })

  const [vehicleAllList, setVehicleAllList] = useState([]);


  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);

  const [assignModal, setAssignModal] = useState(false)
  const [requestId, setRequestId] = useState(null)
  const [tripDetails, setTripDetails] = useState({ pickupDate: "", pickupTime: "", dropDate: "", dropTime: "", vehicleAssigned: "", request_id: null });


  const customStyles = {
    control: (base) => ({
      ...base,
      color: "#f6efe9",
      fontSize: 14,
      borderRadius: 10,
      border: "1px solid #f6efe9",
      backgroundColor: "white",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #f6efe9",
      },
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  const handleVehicleChange = (selectedOption) => {
    setSelectedVehicle(selectedOption); // Update selected vehicle ID
  };

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  let api_key = localStorage?.getItem("api_key") || "";
  let user_customer_id = Number(localStorage?.getItem("customer_id")) || "";

  const getRequestList = () => {
    setLoader(true);
    const payload = {
      customer_id: user_customer_id,
      api_key: api_key,
      page: page,
      limit: 10
    }
    simplePostCall(ApiConfig.MARKET_PLACE_DIRECT_ORDER_LOAD_LIST, JSON.stringify(payload))
      .then((res) => {
        if (res.success) {

          setLoadList(prev => {
            return (
              {
                ...prev,
                data: [...prev.data, ...res.data],
                next_page: res.next_page,
                total: res.total
              }
            )
          });
          setLoader(false);
        }
        setLoader(false);
      }).catch((error) => {
        console.log(error)
      })
  }


  
  const getMyOrderList = () => {
    setLoader(true);
    const payload = {
      customer_id: user_customer_id,
      api_key: api_key,
      page: page,
      limit: 10
    }
    simplePostCall(ApiConfig.MARKET_PLACE_DIRECT_ORDER_MY_ORDER_LIST, JSON.stringify(payload))
      .then((res) => {
        if (res.success) {

          setMyOrderList(prev => {
            return (
              {
                ...prev,
                data: [...prev.data, ...res.data],
                next_page: res.next_page,
                total: res.total
              }
            )
          });
          setLoader(false);
        }
        setLoader(false);
      }).catch((error) => {
        console.log(error)
      })
  }


  const getConfirmList = () => {
    setLoader(true)
    const payload = {
      customer_id: user_customer_id,
      api_key: api_key,
      page: page,
      limit: 10
    }
    simplePostCall(ApiConfig.MARKET_PLACE_DIRECT_ORDER_CONFIRM_LIST, JSON.stringify(payload))
      .then((res) => {
        if (res.success) {
          setConfirmList(prev => {
            return (
              {
                ...prev,
                data: [...prev.data, ...res?.data],
                next_page: res?.next_page,
                total: res?.total
              }
            )
          })
          setLoader(false)
        }
        setLoader(false)
      }).catch((error) => {
        console.log(error)
      })
  }

  const getAllVehicleList = () => {
    let payload = {
      customer_id: user_customer_id
    };

    simplePostCall(ApiConfig.MARKET_PLACE_TRANSPORT_LOOKUP_VEHICLE, JSON.stringify(payload))
      .then((res) => {
        if (res.success) {
          setVehicleAllList(res.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching vehicle list:', error);
      });
  };

  const getOfferedVehicleList = () => {

    setLoader(true);
    let payload = {
      customer_id: user_customer_id,
      api_key: api_key,
      limit: 100,
      page: page,
    };

    simplePostCall(ApiConfig.MARKET_PLACE_DIRECT_ORDER_ORFFERED_LIST, JSON.stringify(payload))
      .then((res) => {
        if (res.success) {
          setVehicleOfferList(prev => {
            const currentList = prev.data || [];
            return (
              {
                ...prev,
                data: [...currentList, ...res.data],
                next_page: res.next_page,
                total: res.total
              }
            )
          })
          console.log(vehicleOfferList);
          setLoader(false);
        }
        setLoader(false);
      }).catch((error) => {
        console.log(error)
      })
  };

  const approveRequest = (request_id) => {
    setLoader(true)
    const payload = {
      customer_id: user_customer_id,
      api_key: api_key,
      offer_vehicle_id: selectedVehicle,
      vehicle_request_id: request_id
    }
    setSelectedVehicle()
    simplePostCall(ApiConfig.MARKET_PLACE_DIRECT_ORDER_REQUEST_APPROVE, JSON.stringify(payload))
      .then((res) => {
        if (res.success) {
          setLoadList({ data: [], next_page: false })
          notifySuccess(res.message)
          getRequestList()
          setLoader(false)
        } else {
          notifyError(res.message)
          setLoader(false)
        }

      }).catch((error) => {
        console.log(error)
        setLoader(false)
      })
  }

  const rejectRequest = (request_id) => {
    setLoader(true)
    const payload = {
      customer_id: user_customer_id,
      api_key: api_key,
      vehicle_request_id: request_id
    }
    simplePostCall(ApiConfig.MARKET_PLACE_DIRECT_ORDER_REQUEST_REJECT, JSON.stringify(payload))
      .then((res) => {
        if (res.success) {
          setLoadList({ data: [], next_page: false })
          notifySuccess(res.message)
          getRequestList()
          setLoader(false)
        } else {
          notifyError(res.message)
          setLoader(false)
        }

      }).catch((error) => {
        console.log(error)
        setLoader(false)
      })
  }

  useEffect(() => {
    getAllVehicleList()

  }, [])


  useEffect(() => {

    switch (vehicleListType) {
      case 1:
        // Load List
        getRequestList()
        break;
      case 2:
        // Offer Vehicle
        getOfferedVehicleList()
        break;
      case 3:
        // Order Confirmation
        getConfirmList()
        break;
      case 4:
        // Freight
        break;
        case 5:
          // Load List
          getMyOrderList()
          break;
        case 2:
      default:
        break;
    }
  }, [vehicleListType])


  // <th>{t("Sr.no")}</th>
  // <th>{t("Pick Up Location")}</th>
  // <th>{t("Drop Loacation")}</th>
  // <th>{t("Pick Date & Time")}</th>
  // <th>{t("Drop Date & time")}</th>
  // <th>{t("Number of vehicles")}</th>
  // <th>{t("Name")}</th>
  // <th>{t("Email")}</th>
  // <th>{t("Number")}</th>
  // <th>{t("Action")}</th>
  // <td>{index + 1}</td>
  // <td>{item.vehicle_request_pickup_location}</td>
  // <td>{item.vehicle_request_drop_location}</td>
  // <td>{item.vehicle_request_pickup_date} {item.vehicle_request_pickup_time}</td>
  // <td>{item.vehicle_request_drop_date} {item.vehicle_request_drop_time}</td>
  // <td>{item.vehicle_request_number_of_vehicles}</td>
  // <td>{item.vehicle_request_customer_name}</td>
  // <td>{item.vehicle_request_customer_email}</td>
  // <td>{item.vehicle_request_customer_number}</td>




  /* lets make reusable component [
  key
  value
  type
  isSort
  isFilter

  ]

*/
  const ActionButtonUi = (item) => {
    return (
      <div className="innerFlex d-flex">
        <Link to="#" onClick={() => {
          setAssignModal(true)
          setTripDetails({
            pickupDate: item.vehicle_request_pickup_date,
            pickupTime: item.vehicle_request_pickup_time,
            dropDate: item.vehicle_request_drop_date,
            dropTime: item.vehicle_request_drop_time,
            vehicleAssigned: item.vehicle_request_number_of_vehicles,
            request_id: item.vehicle_request_id
          })
        }}>
          <div className="inconsIn me-3">
            <img src={ic_check_accept} alt="" />
          </div>
        </Link>
        <Link to="#" onClick={() => {
          setShow2(true)
          setRequestId(item.vehicle_request_id)
        }}>
          <div className="inconsIn me-3">
            <img src={ic_reject_cross} alt="" />
          </div>
        </Link>
      </div>
    )
  }




  const transformedColumns = [
    {
      key: 'sr',
      isFilter: false,
      isSort: true,
      isRender: false,
      title: 'Sr.no',
    },
    {
      key: 'vehicle_request_pickup_location',
      isFilter: true,
      isSort: true,
      isRender: false,
      title: 'Pick Up Location',
    },
    {
      key: 'vehicle_request_drop_location',
      isFilter: true,
      isSort: true,
      isRender: false,
      title: 'Drop Location',
    },
    {
      key: 'vehicle_request_pickup_date_and_time',
      title: 'Pick Date & Time',
      isSort: true,
      isFilter: true,
    },
    {
      key: 'vehicle_request_drop_date_and_time',
      title: 'Drop Date & Time',
      isSort: true,
      isFilter: true,
    },
    {
      key: 'vehicle_request_number_of_vehicles',
      isFilter: true,
      isSort: true,
      isRender: false,
      title: 'Number of Vehicles',
    },
    {
      key: 'vehicle_request_customer_name',
      isFilter: true,
      isSort: true,
      isRender: false,
      title: 'Name',
    },
    {
      key: 'vehicle_request_customer_email',
      isFilter: true,
      isSort: true,
      isRender: false,
      title: 'Email',
    },
    {
      key: 'vehicle_request_customer_number',
      isFilter: true,
      isSort: true,
      isRender: false,
      title: 'Number',
    },
    {
      key: 'action',
      isFilter: false,
      isSort: false,
      isRender: true,
      title: 'Action',
      RenderUI: (record) => ActionButtonUi(record),
    },
  ];


  const offervehicleColumns = [
    {
      key: 'sr',
      isFilter: false,
      isSort: true,
      isRender: false,
      title: 'Sr.no',
    },
    {
      key: 'vehicle_request_pickup_location',
      isFilter: true,
      isSort: true,
      isRender: false,
      title: 'Pick Up Location',
    },
    {
      key: 'vehicle_request_drop_location',
      isFilter: true,
      isSort: true,
      isRender: false,
      title: 'Drop Location',
    },
    {
      key: 'vehicle_request_pickup_date_and_time',
      title: 'Pick Date & Time',
      isSort: true,
      isFilter: true,
    },
    {
      key: 'vehicle_request_drop_date_and_time',
      title: 'Drop Date & Time',
      isSort: true,
      isFilter: true,
    },
    {
      key: 'vehicle_request_number_of_vehicles',
      isFilter: true,
      isSort: true,
      isRender: false,
      title: 'Number of Vehicles',
    },
    {
      key: 'vehicle_request_customer_name',
      isFilter: true,
      isSort: true,
      isRender: false,
      title: 'Name',
    },
    {
      key: 'vehicle_request_customer_email',
      isFilter: true,
      isSort: true,
      isRender: false,
      title: 'Email',
    },
    {
      key: 'vehicle_request_customer_number',
      isFilter: true,
      isSort: true,
      isRender: false,
      title: 'Number',
    },
    {
      key: 'action',
      isFilter: false,
      isSort: false,
      isRender: true,
      title: 'Action',
      RenderUI: (record) => {
        return <Link to={
          "/DirectOrderConfirmation/" +
          record?.vehicle_request_id
        }
        >
          <div className="inconsIn me-3">
            <img src={View} alt="View" />
          </div>
        </Link>
      },
    },
  ];

  const myOrderData = [
    {
      "sr": 1,
      "referenceNo": "RF-1234567890-1111",
      "startCity": "Pune, Kalas Road",
      "startDate": "2023-08-31",
      "endDate": "2023-08-31",
      "status": "Initiated",
      "actionLink": "/DirectOrderConfirmation/1"
    },
    {
      "sr": 2,
      "referenceNo": "RF-1234567890-1111",
      "startCity": "Pune, Kalas Road",
      "startDate": "2023-08-31",
      "endDate": "2023-08-31",
      "status": "Initiated",
      "actionLink": "/DirectOrderConfirmation/2"
    },
    {
      "sr": 3,
      "referenceNo": "RF-1234567890-1111",
      "startCity": "Pune, Kalas Road",
      "startDate": "2023-08-31",
      "endDate": "2023-08-31",
      "status": "Initiated",
      "actionLink": "/DirectOrderConfirmation/3"
    },
    {
      "sr": 4,
      "referenceNo": "RF-1234567890-1111",
      "startCity": "Pune, Kalas Road",
      "startDate": "2023-08-31",
      "endDate": "2023-08-31",
      "status": "Initiated",
      "actionLink": "/DirectOrderConfirmation/4"
    },
    {
      "sr": 5,
      "referenceNo": "RF-1234567890-1111",
      "startCity": "Pune, Kalas Road",
      "startDate": "2023-08-31",
      "endDate": "2023-08-31",
      "status": "Initiated",
      "actionLink": "/DirectOrderConfirmation/5"
    }
  ]

  const myOrderColumns = [
    {
      key: 'sr',
      isFilter: false,
      isSort: true,
      isRender: false,
      title: 'Sr.no',
    },
    {
      key: 'referenceNo',
      isFilter: true,
      isSort: true,
      isRender: false,
      title: 'Reference No.',
    },
    {
      key: 'startCity',
      isFilter: true,
      isSort: true,
      isRender: false,
      title: 'Start City',
    },
    {
      key: 'startDate',
      title: 'Start Date',
      isSort: true,
      isFilter: true,
    },
    {
      key: 'endDate',
      title: 'End Date',
      isSort: true,
      isFilter: true,
    },
    {
      key: 'status',
      isFilter: true,
      isSort: true,
      isRender: false,
      title: 'Status',
    },
    {
      key: 'action',
      isFilter: false,
      isSort: false,
      isRender: true,
      title: 'Action',
      RenderUI: (record) => {
        return (
          <div className="innerFlex d-flex">
            <Link to="#" >
              <div className="inconsIn me-3">
                <img src={ic_r_arrow} alt="" />
              </div>
            </Link>
          </div>
        );
      },
    },
  ];





  return (
    <motion.div
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
    >


      <div id="cx-wrapper" className="Vehicle_Main">
        <div
          className="Vehcle-main-tabs cx-marketPlace-main"
          id="cx-marketPlace"
        >
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="pills" id="newTabMai" className="tob_nav_pills">
              <Nav.Item>
                <Nav.Link eventKey="first" onClick={
                  () => {
                    setVehicleListType(1)
                    setConfirmList({ data: [], next_page: false })
                    setVehicleOfferList({ data: [], next_page: false })
                  }
                }>{t("Order List")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second" onClick={
                  () => {
                    setVehicleListType(2)
                    setLoadList({ data: [], next_page: false })
                  }
                }>{t("Offer Vehicle")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="three" onClick={
                  () => {
                    setVehicleListType(3)
                    setVehicleOfferList({ data: [], next_page: false })
                    setConfirmList({ data: [], next_page: false })
                    setLoadList({ data: [], next_page: false })
                  }
                }>{t("Order Confirmation")}</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="four" onClick={
                  () => {
                    setVehicleListType(4)
                    setVehicleOfferList({data:[],next_page:false})
                    setConfirmList({data:[],next_page:false})
                    setLoadList({data:[],next_page:false})
                  }
                }>{t("Freight")}</Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link eventKey="five" onClick={
                  // () => {
                  //   setVehicleListType(4)
                  //   setVehicleOfferList({data:[],next_page:false})
                  //   setConfirmList({data:[],next_page:false})
                  //   setLoadList({data:[],next_page:false})

                    
                  // }
                  () => {
                    setVehicleListType(4)
                    setVehicleOfferList({ data: [], next_page: false })
                    setConfirmList({ data: [], next_page: false })
                    setLoadList({ data: [], next_page: false })
                  }
                }>{t("My Order")}</Nav.Link>
              </Nav.Item>

            </Nav>
            <Tab.Content>
              {/* Request List */}
              <Tab.Pane eventKey="first">
                <div className="main-master-wrapper">
                  <div id="scroll_insideThe_Padding_tabel">
                    <div className="VA_table">
                      <div className="yauto antTableCounterAndScroll"
                        // id="arrange-paading"
                        onScroll={(e) => {
                          // console.log("i got scroll");
                          setBottom(e ? true : false)
                          const bottom =
                            e.target.scrollHeight - e.target.scrollTop ===
                            e.target.clientHeight

                          if (loadList.next_page != false) {
                            if (bottom) {
                              setPage(loadList.next_page);
                              getRequestList()
                            }
                          }
                        }}
                      >
                        <AntTablewithFilterandSort
                          colunms={transformedColumns}
                          data={loadList.data.map((item, index) => ({
                            ...item, sr: index + 1,
                            vehicle_request_pickup_date_and_time: item?.vehicle_request_pickup_date + " " + item?.vehicle_request_pickup_time,
                            vehicle_request_drop_date_and_time: item?.vehicle_request_drop_date + " " + item?.vehicle_request_drop_time,

                          }))}
                          pagination={false}
                        />
                      </div>
                    </div>
                  </div>
                  <Pagenation length={loadList?.data?.length} total={loadList.total} />
                </div>
              </Tab.Pane>
              {/* Offer Vehicle */}
              <Tab.Pane eventKey="second">
                <div className="main-master-wrapper">
                  <div id="scroll_insideThe_Padding_tabel">
                    <div className="VA_table">
                      <div className="yauto"
                        //  id="arrange-paading"
                        onScroll={(e) => {
                          setBottom(e ? true : false)
                          const bottom =
                            e.target.scrollHeight - e.target.scrollTop ===
                            e.target.clientHeight

                          if (loadList.next_page != false) {
                            if (bottom) {
                              setPage(loadList.next_page);
                              getRequestList()
                            }
                          }
                        }}>
                        <AntTablewithFilterandSort
                          colunms={offervehicleColumns}
                          data={vehicleOfferList?.data.map((item, index) => ({
                            ...item, sr: index + 1,
                            vehicle_request_pickup_date_and_time: item?.vehicle_request_pickup_date + " " + item?.vehicle_request_pickup_time,
                            vehicle_request_drop_date_and_time: item?.vehicle_request_drop_date + " " + item?.vehicle_request_drop_time,

                          }))}
                          pagination={false}
                        />

                        {/* <table className="table tableAdmin">
                          <thead className="tableHead">
                            <tr>
                              <th>{t("Sr.no")}</th>
                              <th>{t("Pick Up Location")}</th>
                              <th>{t("Drop Loacation")}</th>
                              <th>{t("Pick Date & Time")}</th>
                              <th>{t("Drop Date & time")}</th>
                              <th>{t("Number of vehicles")}</th>
                              <th>{t("Name")}</th>
                              <th>{t("Email")}</th>
                              <th>{t("Number")}</th>
                              <th>{t("Offered Vehicle")}</th>
                              <th>{t("Action")}</th>
                            </tr>
                          </thead>
                          <tbody className="tableBody">
                            {
                              loader ? <Loader /> :
                                (vehicleOfferList?.data && vehicleOfferList.data.length > 0) ?
                                  (
                                    vehicleOfferList.data.map((item, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.vehicle_request_pickup_location}</td>
                                        <td>{item.vehicle_request_drop_location}</td>
                                        <td>{item.vehicle_request_pickup_date} {item.vehicle_request_pickup_time}</td>
                                        <td>{item.vehicle_request_drop_date} {item.vehicle_request_drop_time}</td>
                                        <td>{item.vehicle_request_number_of_vehicles}</td>
                                        <td>{item.vehicle_request_customer_name}</td>
                                        <td>{item.vehicle_request_customer_email}</td>
                                        <td>{item.vehicle_request_customer_number}</td>
                                        <td>{item.total_vehicles}</td>
                                        <td>  
                                          <Link to={
                                            "/DirectOrderConfirmation/" +
                                            item?.vehicle_request_id
                                          }
                                          >
                                            <div className="inconsIn me-3">
                                              <img src={View} alt="View" />
                                            </div>
                                          </Link>
                                        </td>
                                      </tr>
                                    ))
                                  ) :
                                  (
                                    <tr>
                                      <td colSpan={10} className='text-center'><NoDataComp /></td>
                                    </tr>
                                  )
                            }

                          </tbody>
                        </table> */}
                      </div>
                    </div>
                  </div>
                  <Pagenation />
                </div>
              </Tab.Pane>
              {/* Confirm Order */}
              <Tab.Pane eventKey="three">
                <div className="main-master-wrapper">
                  <div id="scroll_insideThe_Padding_tabel">
                    <div className="VA_table">

                      <div className="yauto"
                        // id="arrange-paading"
                        onScroll={(e) => {
                          setBottom(e ? true : false)
                          const bottom =
                            e.target.scrollHeight - e.target.scrollTop ===
                            e.target.clientHeight

                          if (loadList.next_page != false) {
                            if (bottom) {
                              setPage(loadList.next_page);
                              getRequestList()
                            }
                          }
                        }}>



                        <AntTablewithFilterandSort
                          colunms={offervehicleColumns}
                          data={confirmList?.data.map((item, index) => ({
                            ...item, sr: index + 1,
                            vehicle_request_pickup_date_and_time: item?.vehicle_request_pickup_date + " " + item?.vehicle_request_pickup_time,
                            vehicle_request_drop_date_and_time: item?.vehicle_request_drop_date + " " + item?.vehicle_request_drop_time,

                          }))}
                          pagination={false}
                        />

                        {/* <table className="table tableAdmin">
                          <thead className="tableHead">
                            <tr>
                              <th>{t("Sr.no")}</th>
                              <th>{t("Pick Up Location")}</th>
                              <th>{t("Drop Loacation")}</th>
                              <th>{t("Pick Date & Time")}</th>
                              <th>{t("Drop Date & time")}</th>
                              <th>{t("Number of vehicles")}</th>
                              <th>{t("Name")}</th>
                              <th>{t("Email")}</th>
                              <th>{t("Number")}</th>
                              <th>{t("Action")}</th>
                            </tr>
                          </thead>
                          <tbody className="tableBody">
                            {
                              loader ? <Loader /> :
                                (confirmList.data && confirmList.data.length > 0) ?
                                  (
                                    confirmList.data.map((item, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.vehicle_request_pickup_location}</td>
                                        <td>{item.vehicle_request_drop_location}</td>
                                        <td>{item.vehicle_request_pickup_date} {item.vehicle_request_pickup_time}</td>
                                        <td>{item.vehicle_request_drop_date} {item.vehicle_request_drop_time}</td>
                                        <td>{item.vehicle_request_number_of_vehicles}</td>
                                        <td>{item.vehicle_request_customer_name}</td>
                                        <td>{item.vehicle_request_customer_email}</td>
                                        <td>{item.vehicle_request_customer_number}</td>
                                        <td>
                                          <div className="innerFlex d-flex">
                                            <Link to={
                                              "/DirectOrderConfirmation/" +
                                              item?.vehicle_request_id
                                            }>
                                              <div className="inconsIn me-3">
                                                <img src={View} alt="View" />
                                                title: 'Name',
                                              </div>
                                            </Link>
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  ) :
                                  (
                                    <tr>
                                      <td colSpan={10} className='text-center'><NoDataComp /></td>
                                    </tr>
                                  )
                            }


                          </tbody>
                        </table> */}
                      </div>
                    </div>
                  </div>
                  <Pagenation />
                </div>
              </Tab.Pane>
              {/* Freight */}
              <Tab.Pane eventKey="four">
                <div className="main-master-wrapper">
                  <div id="scroll_insideThe_Padding_tabel">
                    <div className="VA_table">
                      <table className="table tableAdmin">
                        <thead className="tableHead">
                          <tr>
                            <th>{t("Sr.no")}</th>
                            <th>{t("Reference No.")}</th>
                            <th>{t("Start City")}</th>
                            <th>{t("Start Date")}</th>
                            <th>{t("End Date")}</th>
                            <th>{t("Status")}</th>
                            <th>{t("Action")}</th>

                          </tr>
                        </thead>
                        <tbody className="tableBody">
                          <tr>
                            <td>1</td>
                            <td className="refrs-link">RF-1234567890-1111</td>
                            <td>Pune, Kalas Road</td>
                            <td>2023-08-31</td>
                            <td>2023-08-31</td>
                            <td>Initated</td>

                            <td>
                              <div className="innerFlex d-flex">
                                <Link to="#" >
                                  <div className="inconsIn me-3">
                                    <img src={ic_r_arrow} alt="" />
                                  </div>
                                </Link>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td className="refrs-link">RF-1234567890-1111</td>
                            <td>Pune, Kalas Road</td>
                            <td>2023-08-31</td>
                            <td>2023-08-31</td>
                            <td>Initated</td>

                            <td>
                              <div className="innerFlex d-flex">
                                <Link to="#" >
                                  <div className="inconsIn me-3">
                                    <img src={ic_r_arrow} alt="" />
                                  </div>
                                </Link>
                              </div>
                            </td>
                          </tr><tr>
                            <td>1</td>
                            <td className="refrs-link">RF-1234567890-1111</td>
                            <td>Pune, Kalas Road</td>
                            <td>2023-08-31</td>
                            <td>2023-08-31</td>
                            <td>Initated</td>

                            <td>
                              <div className="innerFlex d-flex">
                                <Link to="#" >
                                  <div className="inconsIn me-3">
                                    <img src={ic_r_arrow} alt="" />
                                  </div>
                                </Link>
                              </div>
                            </td>
                          </tr><tr>
                            <td>1</td>
                            <td className="refrs-link">RF-1234567890-1111</td>
                            <td>Pune, Kalas Road</td>
                            <td>2023-08-31</td>
                            <td>2023-08-31</td>
                            <td>Initated</td>

                            <td>
                              <div className="innerFlex d-flex">
                                <Link to="#" >
                                  <div className="inconsIn me-3">
                                    <img src={ic_r_arrow} alt="" />
                                  </div>
                                </Link>
                              </div>
                            </td>
                          </tr><tr>
                            <td>1</td>
                            <td className="refrs-link">RF-1234567890-1111</td>
                            <td>Pune, Kalas Road</td>
                            <td>2023-08-31</td>
                            <td>2023-08-31</td>
                            <td>Initated</td>

                            <td>
                              <div className="innerFlex d-flex">
                                <Link to="#" >
                                  <div className="inconsIn me-3">
                                    <img src={ic_r_arrow} alt="" />
                                  </div>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Pagenation />
                </div>
              </Tab.Pane>

              {/* My Orders */}
                     <Tab.Pane eventKey="five">
                <div className="main-master-wrapper">
                  <div id="scroll_insideThe_Padding_tabel">
                    <div className="VA_table">
                      <div className="yauto" 
                      // id="arrange-paading"
                        onScroll={(e) => {
                          setBottom(e ? true : false)
                          const bottom =
                            e.target.scrollHeight - e.target.scrollTop ===
                            e.target.clientHeight

                          if (myOrderList.next_page != false) {
                            if (bottom) {
                              setPage(myOrderList.next_page);
                              getMyOrderList()
                            }
                          }
                        }}>
                        <table className="table tableAdmin">
                          <thead className="tableHead">
                            <tr>
                              <th>{t("Sr.no")}</th>
                              <th>{t("Pick Up Location")}</th>
                              <th>{t("Drop Loacation")}</th>
                              <th>{t("Pick Date & Time")}</th>
                              <th>{t("Drop Date & time")}</th>
                              <th>{t("Number of vehicles")}</th>
                              <th>{t("Name")}</th>
                              <th>{t("Email")}</th>
                              <th>{t("Number")}</th>
                              <th>{t("Action")}</th>
                            </tr>
                          </thead>
                          <tbody className="tableBody">
                            {
                              loader ? <Loader /> :
                                (myOrderList?.data && myOrderList.data.length > 0) ?
                                  (
                                    myOrderList.data.map((item, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.vehicle_request_pickup_location}</td>
                                        <td>{item.vehicle_request_drop_location}</td>
                                        <td>{item.vehicle_request_pickup_date} {item.vehicle_request_pickup_time}</td>
                                        <td>{item.vehicle_request_drop_date} {item.vehicle_request_drop_time}</td>
                                        <td>{item.vehicle_request_number_of_vehicles}</td>
                                        <td>{item.vehicle_request_customer_name}</td>
                                        <td>{item.vehicle_request_customer_email}</td>
                                        <td>{item.vehicle_request_customer_number}</td>
                                        <td>
                                          <div className="innerFlex d-flex">
                                            <Link to={
                                              "/DirectOrderConfirmation/" +
                                              item?.vehicle_request_id
                                            }>
                                              <div className="inconsIn me-3">
                                                <img src={View} alt="View" />
                                              </div>
                                            </Link>
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  ) :
                                  (
                                    <tr>
                                      <td colSpan={10} className='text-center'><NoDataComp /></td>
                                    </tr>
                                  )
                            }

                          </tbody>
                        </table>
                      </div>


                      <AntTablewithFilterandSort
                        colunms={myOrderColumns}
                        data={myOrderData}
                        pagination={false}
                      />



                        {/* <table className="table tableAdmin">
                          <thead className="tableHead">
                            <tr>
                              <th>{t("Sr.no")}</th>
                              <th>{t("Reference No.")}</th>
                              <th>{t("Start City")}</th>
                              <th>{t("Start Date")}</th>
                              <th>{t("End Date")}</th>
                              <th>{t("Status")}</th>
                              <th>{t("Action")}</th>

                            </tr>
                          </thead>
                          <tbody className="tableBody">
                            <tr>
                              <td>1</td>
                              <td className="refrs-link">RF-1234567890-1111</td>
                              <td>Pune, Kalas Road</td>
                              <td>2023-08-31</td>
                              <td>2023-08-31</td>
                              <td>Initated</td>

                              <td>
                                <div className="innerFlex d-flex">
                                  <Link to="#" >
                                    <div className="inconsIn me-3">
                                      <img src={ic_r_arrow} alt="" />
                                    </div>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td className="refrs-link">RF-1234567890-1111</td>
                              <td>Pune, Kalas Road</td>
                              <td>2023-08-31</td>
                              <td>2023-08-31</td>
                              <td>Initated</td>

                              <td>
                                <div className="innerFlex d-flex">
                                  <Link to="#" >
                                    <div className="inconsIn me-3">
                                      <img src={ic_r_arrow} alt="" />
                                    </div>
                                  </Link>
                                </div>
                              </td>
                            </tr><tr>
                              <td>1</td>
                              <td className="refrs-link">RF-1234567890-1111</td>
                              <td>Pune, Kalas Road</td>
                              <td>2023-08-31</td>
                              <td>2023-08-31</td>
                              <td>Initated</td>

                              <td>
                                <div className="innerFlex d-flex">
                                  <Link to="#" >
                                    <div className="inconsIn me-3">
                                      <img src={ic_r_arrow} alt="" />
                                    </div>
                                  </Link>
                                </div>
                              </td>
                            </tr><tr>
                              <td>1</td>
                              <td className="refrs-link">RF-1234567890-1111</td>
                              <td>Pune, Kalas Road</td>
                              <td>2023-08-31</td>
                              <td>2023-08-31</td>
                              <td>Initated</td>

                              <td>
                                <div className="innerFlex d-flex">
                                  <Link to="#" >
                                    <div className="inconsIn me-3">
                                      <img src={ic_r_arrow} alt="" />
                                    </div>
                                  </Link>
                                </div>
                              </td>
                            </tr><tr>
                              <td>1</td>
                              <td className="refrs-link">RF-1234567890-1111</td>
                              <td>Pune, Kalas Road</td>
                              <td>2023-08-31</td>
                              <td>2023-08-31</td>
                              <td>Initated</td>

                              <td>
                                <div className="innerFlex d-flex">
                                  <Link to="#" >
                                    <div className="inconsIn me-3">
                                      <img src={ic_r_arrow} alt="" />
                                    </div>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table> */}
                    </div>
                  </div>
                  <Pagenation length={myOrderList?.data?.length} total={myOrderList.total} />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          {/* =========================== Aprove ========================= */}
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="common-model"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Approve Request")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {t("Are you sure you want to Approve this request ?")} ?
            </Modal.Body>
            <Modal.Footer className="pop-up-modal-footer btn-wrapper">
              <button className="cx-btn-1" onClick={handleClose}>
                {t("Cancel")}
              </button>
              <button className="cx-btn-2" onClick={() => navigate("/DirectOrderOfferLoad")} >
                {t("Yes, Approve")}
              </button>
            </Modal.Footer>
          </Modal>

          {/* =============================== Reject ======================= */}
          <Modal
            show={show2}
            onHide={handleClose2}
            centered
            className="common-model"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Reject Request")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {t("Are you sure you want to Reject this request ?")} ?
            </Modal.Body>
            <Modal.Footer className="pop-up-modal-footer btn-wrapper">
              <button className="cx-btn-1" onClick={handleClose2}>
                {t("No")}
              </button>
              <button className="cx-btn-2" onClick={() => {
                setShow2(false)
                rejectRequest(requestId)
              }}>
                {t("Yes")}
              </button>
            </Modal.Footer>
          </Modal>

          {/* Assign Vehicle Modal Start */}
          <Modal
            show={assignModal}
            onHide={() => setAssignModal(false)}
            centered
            className="common-model"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Assign Vehicle")} </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-1">
              <Form noValidate validated={true} onSubmit={(e) => { e.preventDefault(); setAssignModal(false) }}>
                <div className="row">
                  <div className="col-md-6 form_input_main mt-3">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Start Date")}
                      </Form.Label>
                      <div className="innerSelectBox weekCounter datepicker-main">
                        <CommonDatePicker
                          dateKey="pickupDate"
                          setDate={() => { }}
                          data={tripDetails}
                          value={tripDetails.pickupDate}
                          keyDisable={true}
                        />
                      </div>

                      <Form.Control.Feedback>
                        Please Select Start Date
                      </Form.Control.Feedback>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6 form_input_main mt-3">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("Start Time")}
                      </Form.Label>
                      <Space>
                        <TimePicker
                          className="form-control carretClass"
                          placeholder="Start Time"
                          size="large"
                          disabled
                          value={tripDetails.pickupTime ? moment(tripDetails.pickupTime, 'HH:mm') : null}
                        />
                      </Space>
                    </div>
                  </div>
                  <div className="col-md-6 form_input_main ">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("End Date")}
                      </Form.Label>
                      <div className="innerSelectBox weekCounter datepicker-main">
                        <CommonDatePicker
                          dateKey="dropDate"
                          setDate={() => { }}
                          data={tripDetails}
                          value={tripDetails.dropDate}
                          keyDisable={true}
                        />
                      </div>
                      <Form.Control.Feedback>
                        Please Select Start Date
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6 form_input_main">
                    <div className="innerSelectBox weekCounter">
                      <Form.Label className="common-labels">
                        {t("End Time")}
                      </Form.Label>
                      <Space>
                        <TimePicker
                          className="form-control carretClass"
                          placeholder="End Time"
                          size="large"
                          disabled
                          value={tripDetails.dropTime ? moment(tripDetails.dropTime, 'HH:mm') : null}
                        />
                      </Space>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 colForm mb-2">
                    <Form.Label>
                      Vehicles to be Assigned <span>&#42;</span>
                    </Form.Label>
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      value={tripDetails.vehicleAssigned}
                    />
                  </div>
                  <div className="col-md-12 col-sm-12 colForm mb-2">
                    <Form.Label>
                      Vehicles <span>&#42;</span>
                    </Form.Label>

                    <Select
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          neutral50: "rgba(156, 73, 0, 0.5)",
                          primary25: "#f6efe9",
                          primary: "#8f4300",
                          primary75: "#4C9AFF",
                          background: "#8f4300",
                          color: "#8f4300",
                        },
                      })}
                      value={selectedVehicle}
                      onChange={handleVehicleChange}
                      options={vehicleAllList.map((item) => ({
                        label: item.plate_number,
                        value: item.vehicle_id,
                        id: item.vehicle_id,
                      }))}
                      id={true}
                      // value={state?.selectedTopics}
                      // onChange={onTopicChange}
                      // options={gradeState.grades}
                      isMulti={true}
                    />

                    <Form.Control.Feedback>
                      Please select Vehicle
                    </Form.Control.Feedback>
                  </div>
                </div>
                <Modal.Footer>
                  <div className="d-flex justify-content-end align-items-center belowBtns btn-wrapper">
                    <button
                      onClick={() => setAssignModal(false)}
                      type="button"
                      className="cx-btn-1"
                    >
                      {t("Cancel")}
                    </button>
                    <button type="Assign" className="cx-btn-2" onClick={() => {
                      approveRequest(tripDetails.request_id)
                    }}>
                      {t("Assign")}
                    </button>
                  </div>
                </Modal.Footer>
              </Form>
            </Modal.Body>
          </Modal>
          {/* Assign Vehicle Modal End */}


        </div>
      </div>
    </motion.div>
  );
};

export default DirectOrderMarketPlace;
