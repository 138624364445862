// Usama 09-02-2023
import React, { useContext, useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Carousel, Col, Dropdown, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import "@splidejs/react-splide/css";
import ic_check_accept from "../../assets/images/ic_check_accept.svg";
import Export from "../../assets/images/ic-Export.svg";
import Import from "../../assets/images/ic-Import.svg";
import ic_reject_cross from "../../assets/images/ic_reject_cross.svg";
import { motion } from "framer-motion";
import Delete from "../../assets/images/delete.svg";
import View from "../../assets/images/Group.svg";
import Nav from "react-bootstrap/Nav";
import ic_r_arrow from "../../assets/images/ic_r_arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import Pagenation from "../../sharedComponent/Pagenation";
import { useTranslation } from "react-i18next";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import CommonDatePicker from "../../sharedComponent/CommonDatePicker";
import { Space, TimePicker } from "antd";
import Select from "react-select";
import NoDataComp from "../../sharedComponent/NoDataComp";
import { tr } from "date-fns/locale";
import { toast } from "react-toastify";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import moment from "moment";
import AntTablewithFilterandSort from "../../sharedComponent/AntTablewithFilterandSort";

const AcceptedRequests = () => {
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false)
    const [show2, setShow2] = useState(false);
    const handleClose = () => setShow(false);
    const handleClose2 = () => setShow2(false);
    const [bottom, setBottom] = useState("");
    const [page, setPage] = useState(1);



    const [loadList, setLoadList] = useState({
        data: [],
        next_page: false,
        total: 0
    })




    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);

    const [assignModal, setAssignModal] = useState(false)
    const [requestId, setRequestId] = useState(null)
    const [tripDetails, setTripDetails] = useState({ pickupDate: "", pickupTime: "", dropDate: "", dropTime: "", vehicleAssigned: "", request_id: null });


    const customStyles = {
        control: (base) => ({
            ...base,
            color: "#f6efe9",
            fontSize: 14,
            borderRadius: 10,
            border: "1px solid #f6efe9",
            backgroundColor: "white",
            boxShadow: "none",
            "&:hover": {
                border: "1px solid #f6efe9",
            },
            // This line disable the blue border
            boxShadow: "none",
        }),
    };


    const aninations = {
        initial: { opacity: 0, x: 400 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 100 },
    };

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    let api_key = localStorage?.getItem("api_key") || "";
    let user_customer_id = Number(localStorage?.getItem("customer_id")) || "";

    const getRequestList = () => {
        setLoader(true);
        const payload = {
            customer_id: user_customer_id,
            api_key: api_key,
            page: page,
            limit: 10
        }
        simplePostCall(ApiConfig.ACCEPT_REQUEST_LIST, JSON.stringify(payload))
            .then((res) => {
                if (res.success) {

                    setLoadList(prev => {
                        return (
                            {
                                ...prev,
                                data: [...prev.data, ...res.data],
                                next_page: res.next_page,
                                total: res.total
                            }
                        )
                    });
                    setLoader(false);
                }
                setLoader(false);
            }).catch((error) => {
                console.log(error)
            })
    }




    const approveRequest = (request_id, vehicle_offered_from_customer_id) => {
        setLoader(true)
        const payload = {
            customer_id: user_customer_id,
            api_key: api_key,
            from_customer_id: vehicle_offered_from_customer_id,
            vehicle_request_id: request_id
        }
        simplePostCall(ApiConfig.ACCEPT_REQUEST_ACCEPTS_REQUEST, JSON.stringify(payload))
            .then((res) => {
                if (res.success) {
                    setLoadList({ data: [], next_page: false })
                    notifySuccess(res.message)
                    getRequestList()
                    setLoader(false)
                } else {
                    notifyError(res.message)
                    setLoader(false)
                }

            }).catch((error) => {
                console.log(error)
                setLoader(false)
            })
    }

    const rejectRequest = (request_id) => {
        setLoader(true)
        const payload = {
            customer_id: user_customer_id,
            api_key: api_key,
            vehicle_request_id: request_id
        }
        simplePostCall(ApiConfig.MARKET_PLACE_DIRECT_ORDER_REQUEST_REJECT, JSON.stringify(payload))
            .then((res) => {
                if (res.success) {
                    setLoadList({ data: [], next_page: false })
                    notifySuccess(res.message)
                    getRequestList()
                    setLoader(false)
                } else {
                    notifyError(res.message)
                    setLoader(false)
                }

            }).catch((error) => {
                console.log(error)
                setLoader(false)
            })
    }



    useEffect(() => {
        getRequestList();
    }, [])


    const columns = [
        {
            key: 'sr',
            isFilter: false,
            isSort: true,
            isRender: false,
            title: 'Sr.no',
        },
        {
            key: 'vehicle_request_pickup_location',
            isFilter: true,
            isSort: true,
            isRender: false,
            title: 'Pick Up Location',
        },
        {
            key: 'vehicle_request_drop_location',
            isFilter: true,
            isSort: true,
            isRender: false,
            title: 'Drop Location',
        },
        {
            key: 'vehicle_request_pickup_date_and_time',
            title: 'Pick Date & Time',
            isSort: true,
            isFilter: true,
            isRender: false,
        },
        {
            key: 'vehicle_request_drop_date_and_time',
            title: 'Drop Date & Time',
            isSort: true,
            isFilter: true,
        },
        {
            key: 'vehicle_request_number_of_vehicles',
            isFilter: true,
            isSort: true,
            isRender: false,
            title: 'Number of Vehicles',
        },
        {
            key: 'vehicle_request_customer_name',
            isFilter: true,
            isSort: true,
            isRender: false,
            title: 'Name',
        },
        {
            key: 'vehicle_request_customer_email',
            isFilter: true,
            isSort: true,
            isRender: false,
            title: 'Email',
        },
        {
            key: 'vehicle_request_customer_number',
            isFilter: true,
            isSort: true,
            isRender: false,
            title: 'Number',
        },
        {
            key: 'action',
            isFilter: false,
            isSort: false,
            isRender: true,
            title: 'Action',
            RenderUI: (record) => {
                return (
                    <div className="innerFlex d-flex">
                        <Link to="#" onClick={() => {
                            setAssignModal(true)
                            setTripDetails({
                                pickupDate: record.vehicle_request_pickup_date,
                                pickupTime: record.vehicle_request_pickup_time,
                                dropDate: record.vehicle_request_drop_date,
                                dropTime: record.vehicle_request_drop_time,
                                vehicleAssigned: record.vehicle_request_number_of_vehicles,
                                request_id: record.vehicle_request_id,
                                vehicle_offered_from_customer_id: record.vehicle_offered_from_customer_id
                            })
                        }}>
                            <div className="inconsIn me-3">
                                <img src={ic_check_accept} alt="" />
                            </div>
                        </Link>
                        <Link to="#" onClick={() => {
                            setShow2(true)
                            setRequestId(record.vehicle_request_id)
                        }}>
                            <div className="inconsIn me-3">
                                <img src={ic_reject_cross} alt="" />
                            </div>
                        </Link>
                    </div>
                );
            },
        },
    ];



    return (
        <motion.div
            variants={aninations}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.1 }}
            className={sidebar ? "taskMain " : "cx-active taskMain"}
            id="cx-main"
        >
            <div id="cx-wrapper" className="Vehicle_Main">
                <div
                    className="Vehcle-main-tabs cx-marketPlace-main"
                    id="cx-marketPlace"
                >
                    {/* Request List */}
                    <div eventKey="first">
                        <div className="main-master-wrapper">
                            <div id="scroll_insideThe_Padding_tabel">
                                <div className="VA_table">
                                    <div className="yauto"
                                        // id="arrange-paading"
                                        onScroll={(e) => {
                                            setBottom(e ? true : false)
                                            const bottom =
                                                e.target.scrollHeight - e.target.scrollTop ===
                                                e.target.clientHeight

                                            if (loadList.next_page != false) {
                                                if (bottom) {
                                                    setPage(loadList.next_page);
                                                    getRequestList()
                                                }
                                            }
                                        }}>

                                        <AntTablewithFilterandSort
                                            colunms={columns}
                                            data={loadList?.data?.map((item, index) => ({
                                                ...item,
                                                sr: index + 1,
                                                vehicle_request_pickup_date_and_time: item?.vehicle_request_pickup_date + ' ' + item?.vehicle_request_pickup_time,
                                                vehicle_request_drop_date_and_time: item?.vehicle_request_drop_date + ' ' + item?.vehicle_request_drop_time
                                            }))}
                                            pagination={false}
                                        />

                                        {/* <table className="table tableAdmin">
                                            <thead className="tableHead">
                                                <tr>
                                                    <th>{t("Sr.no")}</th>
                                                    <th>{t("Pick Up Location")}</th>
                                                    <th>{t("Drop Loacation")}</th>
                                                    <th>{t("Pick Date & Time")}</th>
                                                    <th>{t("Drop Date & time")}</th>
                                                    <th>{t("Number of vehicles")}</th>
                                                    <th>{t("Name")}</th>
                                                    <th>{t("Email")}</th>
                                                    <th>{t("Number")}</th>
                                                    <th>{t("Action")}</th>
                                                </tr>
                                            </thead>
                                            <tbody className="tableBody">
                                                {
                                                    loader ? <Loader /> :
                                                        (loadList?.data && loadList.data.length > 0) ?


                                                            (
                                                                loadList.data.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{item.vehicle_request_pickup_location}</td>
                                                                        <td>{item.vehicle_request_drop_location}</td>
                                                                        <td>{item.vehicle_request_pickup_date} {item.vehicle_request_pickup_time}</td>
                                                                        <td>{item.vehicle_request_drop_date} {item.vehicle_request_drop_time}</td>
                                                                        <td>{item.vehicle_request_number_of_vehicles}</td>
                                                                        <td>{item.vehicle_request_customer_name}</td>
                                                                        <td>{item.vehicle_request_customer_email}</td>
                                                                        <td>{item.vehicle_request_customer_number}</td>
                                                                        <td>
                                                                            <div className="innerFlex d-flex">
                                                                                <Link to="#" onClick={() => {
                                                                                    setAssignModal(true)
                                                                                    setTripDetails({
                                                                                        pickupDate: item.vehicle_request_pickup_date,
                                                                                        pickupTime: item.vehicle_request_pickup_time,
                                                                                        dropDate: item.vehicle_request_drop_date,
                                                                                        dropTime: item.vehicle_request_drop_time,
                                                                                        vehicleAssigned: item.vehicle_request_number_of_vehicles,
                                                                                        request_id: item.vehicle_request_id,
                                                                                        vehicle_offered_from_customer_id: item.vehicle_offered_from_customer_id
                                                                                    })
                                                                                }}>
                                                                                    <div className="inconsIn me-3">
                                                                                        <img src={ic_check_accept} alt="" />
                                                                                    </div>
                                                                                </Link>
                                                                                <Link to="#" onClick={() => {
                                                                                    setShow2(true)
                                                                                    setRequestId(item.vehicle_request_id)
                                                                                }}>
                                                                                    <div className="inconsIn me-3">
                                                                                        <img src={ic_reject_cross} alt="" />
                                                                                    </div>
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            )
                                                            :
                                                            (
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'><NoDataComp /></td>
                                                                </tr>
                                                            )
                                                }

                                            </tbody>
                                        </table> */}
                                    </div>
                                </div>
                            </div>
                            <Pagenation length={loadList?.data?.length} total={loadList.total} />
                        </div>
                    </div>


                    {/* =========================== Aprove ========================= */}
                    <Modal
                        show={show}
                        onHide={handleClose}
                        centered
                        className="common-model"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Approve Request")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {t("Are you sure you want to Approve this request ?")} ?
                        </Modal.Body>
                        <Modal.Footer className="pop-up-modal-footer btn-wrapper">
                            <button className="cx-btn-1" onClick={handleClose}>
                                {t("Cancel")}
                            </button>
                            <button className="cx-btn-2" onClick={() => navigate("/DirectOrderOfferLoad")} >
                                {t("Yes, Approve")}
                            </button>
                        </Modal.Footer>
                    </Modal>

                    {/* =============================== Reject ======================= */}
                    <Modal
                        show={show2}
                        onHide={handleClose2}
                        centered
                        className="common-model"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Reject Request")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {t("Are you sure you want to Reject this request ?")} ?
                        </Modal.Body>
                        <Modal.Footer className="pop-up-modal-footer btn-wrapper">
                            <button className="cx-btn-1" onClick={handleClose2}>
                                {t("No")}
                            </button>
                            <button className="cx-btn-2" onClick={() => {
                                setShow2(false)
                                rejectRequest(requestId)
                            }}>
                                {t("Yes")}
                            </button>
                        </Modal.Footer>
                    </Modal>

                    {/* Assign Vehicle Modal Start */}
                    <Modal
                        show={assignModal}
                        onHide={() => setAssignModal(false)}
                        centered
                        className="common-model"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Assign Vehicle")} </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pb-1">
                            <div className="row">
                                <div>Are you sure to accept
                                </div>
                            </div>
                            <Modal.Footer>
                                <div className="d-flex justify-content-end align-items-center belowBtns btn-wrapper">
                                    <button
                                        onClick={() => setAssignModal(false)}
                                        type="button"
                                        className="cx-btn-1"
                                    >
                                        {t("Cancel")}
                                    </button>
                                    <button type="Assign" className="cx-btn-2" onClick={() => {
                                        setAssignModal(false)
                                        approveRequest(tripDetails?.request_id, tripDetails?.vehicle_offered_from_customer_id)
                                    }}>
                                        {t("Accept")}
                                    </button>
                                </div>

                            </Modal.Footer>

                        </Modal.Body>
                    </Modal>
                    {/* Assign Vehicle Modal End */}


                </div>
            </div>
        </motion.div>
    );
};

export default AcceptedRequests;
