import { Table } from 'antd';
import React from 'react'

function AntTablewithFilterandSort({ colunms, data, pagination }) {

    /* const tempp = {
         title: 'Email',
         dataIndex: 'vehicle_request_customer_email',
         filters: Array.from(
             new Map(
                 loadList?.data?.map(item => [item?.vehicle_request_customer_email, {
                     value: item?.vehicle_request_customer_email,
                     text: item?.vehicle_request_customer_email
                 }])
             ).values()
         ),
         onFilter: (value, record) => {
             if (record.vehicle_request_customer_email) {
                 return record.vehicle_request_customer_email === value;
             } else {
                 if (!record.vehicle_request_customer_email && !value) {
                     return true;
                 }
             }
         },
         sorter: ((a, b) => {
             if (a.vehicle_request_customer_email < b.vehicle_request_customer_email) return -1;
             if (a.vehicle_request_customer_email > b.vehicle_request_customer_email) return 1;
             return 0;
         })
     }*/


    const newColunms = colunms.map(({ key, isFilter, isSort, isRender, title, RenderUI }) => {
        const obj = {};
        obj.title = title;
        if (!isRender) {
            // if (!key) return;

            obj.dataIndex = key;
            if (isSort) {
                obj.sorter = ((a, b) => {
                    console.log(a[key], b[key]);
                    if (!a[key] && !b[key]) return 0;
                    if (!a[key]) return -1;
                    if (!b[key]) return 1;
                    if (a[key] < b[key]) return -1;
                    if (a[key] > b[key]) return 1;
                    return 0;
                })
            }

            if (isFilter) {
                obj.filters = Array.from(
                    new Map(
                        data?.map(item => [item[key], {
                            value: item[key],
                            text: item[key]
                        }])
                    ).values()
                );
                obj.onFilter = (value, record) => {
                    if (record[key]) {
                        return record[key] === value;
                    } else {
                        if (!record[key] && !value) {
                            return true;
                        }
                    }
                }
            }
            return obj
        } else {
            obj.render = (record) => {
                return RenderUI(record);
            }
            return obj;
        }
    })


    return (
        <div>
            <Table
                columns={newColunms}
                dataSource={data}
                // onChange={onChange}
                // showSorterTooltip={{
                //   target: 'sorter-icon',
                // }}  
                pagination={pagination}
                className="antTable"
            />
        </div>
    )
}

export default AntTablewithFilterandSort