import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";

import { Tab, Tabs, Form, Button } from "react-bootstrap";
import { PostCallWithErrorResponse, postWithAuthCall, simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../sharedComponent/Loader";


const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };


function AddRemark({type}){


    const {id} = useParams()

    const [remark,setRemark] = useState('')

    const navigate = useNavigate()


  const [validated, setValidated] = useState(false);

  const pathname = useLocation().pathname

  

    const {
        sidebar,
        setSidebar,
        Dark,
        setDark,
        recordsPerPage,
        useDebounce,
        DateFormatConfig,
        remarkDetails
      } = useContext(AppContext);

      const [isLoading,setIsLoading] = useState(true)

      useEffect(()=>{

        setTimeout(()=>{

            setIsLoading(false)

        },1000)
        

        if(id!='new'){
    
            setRemark(remarkDetails?.remark_name)
    
        }
    
      },[id,pathname])


      const addEditRemark=()=>{

        setIsLoading(true)

        let body=id=='new'?{
            type:type,
            remark_name:remark,
            status:1
        }:{
            remark_id:remarkDetails?.remark_id,
            type:type,
            remark_name:remark,
        }

        let endPoint=id=="new"?'add-remark':'update-remark'

        simplePostCall(ApiConfig?.REMARKS+endPoint,JSON.stringify(body)).then((res)=>{

            if(res?.result){

                notifySuccess(res?.message)

                navigate(type==='delivery'?"/MerchantCollectionRemark":"/CustomerCollectionRemark")

            }else{
                notifyError(res?.message)
            }
            
            setIsLoading(false)

        }).catch((err)=>{

            console.log(err);
            
            setIsLoading(false)
            

        })

      }

      const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            addEditRemark()
        }
        setValidated(true)
    }

    return(
        <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        <div id="cx-wrapper" className="Holidays_Main">
        <div className="main-master-wrapper inner-tabs-section overflow-hidden length-height">
            {
                isLoading?
                <Loader/>
            :
        <div>
            <h5>{id!='new' ? "Update": "Add"} Remark</h5>
            <Form            
            noValidate
            validated={validated}
            onSubmit={(e) => handleSubmit(e)}
            >
            <div className="row">
            <div className="col-md-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Remark Name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Remark Name"
                        value={remark}
                        onChange={(e)=>setRemark(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Remark Name
                      </Form.Control.Feedback>
                    </Form.Group>
            </div>

            <div className="btn-wrapper">
            <button
                    type="submit"
                    className="cx-btn-2"
                    
                  >
                    
                      {/* <div class="spinner-border cx-btn-load" role="status">
                        <span class="sr-only"> </span>
                      </div> */}
                
                    {id!='new' ? "Update": "Submit"}
                  </button>
            </div>
            </div>
            </Form>
        </div>
}
        </div>
        </div>
      </motion.div>
    )

}

export default AddRemark